import React, { useState, useEffect } from "react";
import { File, User, UserCheck } from "feather-icons-react/build/IconComponents";
import { useNavigate } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster } from "../../Utils/Toaster";
import Chart from "react-apexcharts";
import useFetch from "../../ApiClient/GetApi";
import Loader from "../../Utils/Loader";

const Dashboard = () => {
  const [stations, setStations] = useState([]);
  const [parliament, setParliament] = useState([]);
  const [partyId, setPartyId] = useState("");
  const [constId, setConstId] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [pieGraphData, setPieGraphData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedRole = localStorage.getItem("role");
    if (storedRole === "agent") {
      navigate("/agent-dashboard");
    }
    if (storedRole === "controller") {
      navigate("/controller-dashboard");
    }
    if (storedRole === "admin") {
      navigate("/admin-dashboard");
    }
  }, [navigate]);

  const { apiData } = useFetch("adminDashboard");

  const selectedConsistuency = async (id) => {
    if (partyId === "") {
      error_toaster("Please select Party");
      return false;
    }
    setLoading(true);
    setConstId(id);

    try {
      let response = await PostApi("graphData", { id, partyId });
      const responseData = response?.data?.data || {};

      // Log the response data to verify
      console.log("Response Data: ", responseData);

      // Update state with the new structure based on your provided data
      setStations(responseData?.chartData?.presidentialPollingStatus || []);
      setParliament(responseData?.chartData?.parliamentaryPollingStatus || []);
      setCandidates(responseData?.chartData?.parties || []);
      setCandidates(responseData?.chartData?.parties || []);
      setPieGraphData(responseData?.pieGraph || {});

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const [chartOptions, setChartOptions] = useState({
    series: [
      { name: "Parliamentary", data: [] },
      { name: "Presidential", data: [] },
    ],
    colors: ["#28C76F", "#ff0000"],
    chart: {
      type: "bar",
      height: 320,
      stacked: true,
      zoom: { enabled: true },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: "20%",
      },
    },
    dataLabels: { enabled: false },
    yaxis: { min: 0, max: 300, tickAmount: 5 },
    xaxis: { categories: [] },
    legend: { show: false },
    fill: { opacity: 1 },
  });

  useEffect(() => {
    if (candidates && candidates?.length > 0) {
      const categories = [];
      const parliamentaryVotes = [];
      const presidentialVotes = [];
      let maxVote = 300;

      candidates.forEach((party) => {
        categories.push(party.name);

        const presidentialVote = party.types?.find((vote) => vote.type === "Presidential")?.votes || 0;
        const parliamentaryVote = party.types?.find((vote) => vote.type === "Parliamentary")?.votes || 0;

        presidentialVotes.push(presidentialVote);
        parliamentaryVotes.push(parliamentaryVote);

        maxVote = Math.max(maxVote, presidentialVote, parliamentaryVote);
      });

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          { name: "Parliamentary", data: parliamentaryVotes },
          { name: "Presidential", data: presidentialVotes },
        ],
        xaxis: { categories: categories?.length > 0 ? categories : [""] },
        yaxis: { ...prevOptions.yaxis, max: maxVote + 50 },
      }));
    }
  }, [candidates]);

  const [pieChartOptions, setPieChartOptions] = useState({
    chart: { type: "pie" },
    labels: ["Polled", "Not Polled"],
    colors: ["#bce6ad", "#e6adbc"],
    legend: { position: "bottom" },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          formatter: (val, opts) => opts.w.config.series[opts.seriesIndex] + "%",
        },
      },
    },
  });

  useEffect(() => {
    if (stations && stations?.length > 0) {
      // Ensure the values are parsed correctly as numbers and log the values for debugging
      const polled = parseInt(pieGraphData?.presidential?.polled) || 0;
      const notPolled = parseInt(pieGraphData?.presidential?.notPolled) || 0;

      console.log("Presidential Pie Data: ", { polled, notPolled });

      setPieChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [polled, notPolled],
      }));
    } else {
      setPieChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [0, 100],
      }));
    }
  }, [stations]);

  const [parliamentPieChartOptions, setParliamentPieChartOptions] = useState({
    chart: { type: "pie" },
    labels: ["Polled", "Not Polled"],
    colors: ["#bce6ad", "#e6adbc"],
    legend: { position: "bottom" },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          formatter: (val, opts) => opts.w.config.series[opts.seriesIndex] + "%",
        },
      },
    },
  });

  useEffect(() => {
    if (parliament && parliament?.length > 0) {
      const polled = parseInt(pieGraphData?.parliamentary?.polled) || 0;
      const notPolled = parseInt(pieGraphData?.parliamentary?.notPolled) || 0;

      console.log("Parliamentary Pie Data: ", { polled, notPolled });

      setParliamentPieChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [polled, notPolled],
      }));
    } else {
      setParliamentPieChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [0, 100],
      }));
    }
  }, [parliament]);

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="row">
              <div className="col-xl-3 col-sm-6 col-12 d-flex ">
                <div className="dash-count" style={{background:"#ff0000"}}>
                  <div className="dash-counts">
                    <h4>{apiData?.data?.regionCount}</h4>
                    <h5>Total Regions</h5>
                  </div>
                  <div className="dash-imgs">
                    <User />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-count das1  bg-info">
                  <div className="dash-counts">
                    <h4>{apiData?.data?.presidentialCandidates}</h4>
                    <h5>Presidential Candidates</h5>
                  </div>
                  <div className="dash-imgs">
                    <UserCheck />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-count das2  bg-pink">
                  <div className="dash-counts">
                    <h4>{apiData?.data?.constituencyCount}</h4>
                    <h5>Total Constituencies</h5>
                  </div>
                  <div className="dash-imgs">
                    <ImageWithBasePath
                      src="assets/img/icons/file-text-icon-01.svg"
                      className="img-fluid"
                      alt="icon"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-count das3 bg-success">
                  <div className="dash-counts">
                    <h4>{apiData?.data?.pollingstationCount}</h4>
                    <h5>Total Polling Stations</h5>
                  </div>
                  <div className="dash-imgs">
                    <File />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-count das3 bg-danger">
                  <div className="dash-counts">
                    <h4>{apiData?.data?.agents}</h4>
                    <h5>Total Agents</h5>
                  </div>
                  <div className="dash-imgs">
                    <User />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-count das2 bg-info">
                  <div className="dash-counts">
                    <h4>{apiData?.data?.parliamentaryCandidates}</h4>
                    <h5>Parlimentary Candidates</h5>
                  </div>
                  <div className="dash-imgs">
                    <ImageWithBasePath
                      src="assets/img/icons/file-text-icon-01.svg"
                      className="img-fluid"
                      alt="icon"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-count das1 bg-pink">
                  <div className="dash-counts">
                    <h4>{apiData?.data?.parties}</h4>
                    <h5>Total Parties</h5>
                  </div>
                  <div className="dash-imgs">
                    <UserCheck />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-count bg-success">
                  <div className="dash-counts">
                    <h4>{apiData?.data?.totalVoters}</h4>
                    <h5>Total Voters</h5>
                  </div>
                  <div className="dash-imgs">
                    <User />
                  </div>
                </div>
              </div>
            </div>
          <div className="row">
            <div className="col-xl-12 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Constituency Report</h5>
                  <div className="graph-sets">
                    <ul className="mb-0">
                      <li>
                        <span>Parliamentary</span>
                      </li>
                      <li>
                        <span>Presidential</span>
                      </li>
                    </ul>
                    <div className="dropdown dropdown-wraper" style={{ margin: "5px" }}>
                      <select
                        value={partyId}
                        onChange={(e) => setPartyId(e.target.value)}
                        className="form-control bg-danger text-white"
                      >
                        <option>Select Party</option>
                        {apiData?.data?.partiesData?.map((cons) => (
                          <option key={cons.id} value={cons.id}>
                            {cons?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="dropdown dropdown-wraper">
                      <select
                        value={constId}
                        onChange={(e) => selectedConsistuency(e.target.value)}
                        className="form-control bg-danger text-white"
                      >
                        <option>Select Constituency</option>
                        {apiData?.data?.consistuencies?.map((cons) => (
                          <option key={cons.id} value={cons.id}>
                            {cons?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {candidates?.length > 0 && (
                    <Chart options={chartOptions} series={chartOptions.series} type="bar" height={320} />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Pie Charts Section */}
          <div className="row">
            {/* Presidential Pie Chart */}
            <div className="col-xl-6 col-sm-6 col-12 d-flex">
              <div className="card flex-fill">
                <p className="p-2">Presidential</p>
                {stations?.length > 0 && (
                  <Chart options={pieChartOptions} series={pieChartOptions.series} type="pie" height={320} />
                )}
              </div>
            </div>
            {/* Parliamentary Pie Chart */}
            <div className="col-xl-6 col-sm-6 col-12 d-flex">
              <div className="card flex-fill">
                <p className="p-2">Parliamentary</p>
                {parliament?.length > 0 && (
                  <Chart options={parliamentPieChartOptions} series={parliamentPieChartOptions.series} type="pie" height={320} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
