import React, { useEffect, useState, useRef } from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import { PostApi } from "../../ApiClient/PostApi";
import Loader from "../../Utils/Loader";

const ControllerDistrict = () => {

    const [districtData, setDistrictData] = useState([]);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null); // Ref for file input
    const { apiData, reFetch } = useFetch(`getAllDistricts/${localStorage.getItem('userId')}`);
    console.log(apiData)
    useEffect(() => {
        if (apiData && apiData.data && apiData.data.list) {
            let formattedData = apiData.data.list.map((district) => ({
                key: district.id,
                DistrictName: district.name,
                capital: district.capital ? district?.capital : "No Data",
                DistrictCode: district.code,
                RegionName: district?.region?.name,
                regionId: district?.region?.id,
                type: district.type ? district?.type : "No Data",
                NoOfConstituencies: district.constituencyCount,
                NoOfPollingStations: district.totalPollingStations,
                NoOfVoters: district.totalVoters,
            }));


            setDistrictData(formattedData);
        }
        // Only run this effect when apiData is initially loaded or when the role/userDistrict changes
        // Ensure userDistrict is not causing unnecessary re-renders
    }, [apiData]);

    const columns = [
        {
            title: "District Name",
            dataIndex: "DistrictName",
            sorter: (a, b) => a.DistrictName.length - b.DistrictName.length,
        },
        {
            title: "District Code",
            dataIndex: "DistrictCode",
            sorter: (a, b) => a.DistrictCode.length - b.DistrictCode.length,
        },
        {
            title: "District Type",
            dataIndex: "type",
            sorter: (a, b) => a.RegionName.length - b.RegionName.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "Capital",
            dataIndex: "capital",
            sorter: (a, b) => a.RegionName.length - b.RegionName.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "Region Name",
            dataIndex: "RegionName",
            sorter: (a, b) => a.RegionName.length - b.RegionName.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "No. of Constituencies",
            dataIndex: "NoOfConstituencies",
            sorter: (a, b) => a.NoOfConstituencies - b.NoOfConstituencies,
        },
        {
            title: "No. of Polling Stations",
            dataIndex: "NoOfPollingStations",
            sorter: (a, b) => a.NoOfPollingStations - b.NoOfPollingStations,
            render: (status) => <span className="btn btn-secondary btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "No. of Voters",
            dataIndex: "NoOfVoters",
            sorter: (a, b) => a.NoOfVoters - b.NoOfVoters,
        },

    ];



    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            error_toaster("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        setLoading(true);
        const response = await PostApi("addDistrictFromSheet", formData, true);
        if (response.data.status === "1") {
            reFetch();
            setLoading(false);
            success_toaster(response.data.message);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };


    return (
        <div className="page-wrapper">
            {loading ? <Loader /> : <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>District Management</h4>
                            <h6>Manage Districts</h6>
                        </div>
                    </div>


                </div>

                {/* District list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                    <Link to="#" className="btn btn-searchset">
                                        <Search className="feather-search" />
                                    </Link>
                                </div>
                            </div>

                        </div>
                        {/* Filter */}
                        <div className={`card `} id="filter_inputs">
                            <div className="card-body pb-0">
                                <div className="row">


                                </div>
                            </div>
                        </div>
                        <Table columns={columns} dataSource={districtData} pagination={{ pageSize: 5 }} />
                    </div>
                </div>
                {/* Hidden file input */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                />
                {/* Add/Edit District Modal */}

            </div>}
        </div>
    );
};

export default ControllerDistrict;
