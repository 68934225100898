import React, { useEffect, useState, useMemo } from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import useFetch from "../../ApiClient/GetApi";
import { Trash2 } from "feather-icons-react/build/IconComponents";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../Utils/urls";

const LatestNews = () => {
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [show, setShow] = useState(false);
    const [news, setNews] = useState(""); // Only news field
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newsList, setNewsList] = useState([]);
    const [selectedNewsId, setSelectedNewsId] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const handleCaptcha = (value) => {
        setCaptchaToken(value);
    };

    const { apiData, reFetch } = useFetch("getAllLatestNews");

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setEdit(false);
        setShow(true);
        setNews(""); // Reset news field
    };

    const transformedData = useMemo(() => {
        if (apiData?.data?.data) {
            return apiData.data.data.map((newsItem) => ({
                id: newsItem.id,
                news: newsItem.news,
                createdAt: newsItem.createdAt,
                updatedAt: newsItem.updatedAt,
            }));
        }
        return [];
    }, [apiData]);

    useEffect(() => {
        setNewsList(transformedData);
        setRole(localStorage.getItem('role'));
        setPermissions(JSON.parse(localStorage.getItem('permissions')) || []);
    }, [apiData]);

   
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!captchaToken) {
            error_toaster("Please complete the reCAPTCHA.");
            return;
        }
        const newsData = {
            news: news, // Replace "newsData" key with "news" for consistency in JSON
            captcha: captchaToken,
        };
        
        if (edit) newsData.id = selectedNewsId; // Include the ID if editing
    
        setLoading(true);
    
        try {
            let response;
            if (edit) {
                response = await PostApi("updateLatestNews", newsData);
            } else {
                response = await PostApi("addLatestNews", newsData);
            }
    
            if (response.data.status === "1") {
                reFetch();
                success_toaster(response.data.message);
                setShow(false);
            } else {
                error_toaster(response.data.message);
            }
        } catch (error) {
            error_toaster("An error occurred while submitting the form.");
        } finally {
            setLoading(false);
        }
    };
    

    const deleteNews = async (id) => {
        setLoading(true);
        let response = await PostApi("deleteLatestNews", { id });
        if (response.data.status === "1") {
            success_toaster(response.data.message);
            reFetch();
            setLoading(false);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const handleEdit = (newsItem) => {
        setNews(newsItem.news);
        setSelectedNewsId(newsItem.id); // Set the ID of the news item being edited
        setEdit(true);
        setShow(true);
    };

    const columns = [
        {
            title: "News",
            dataIndex: "news",
            sorter: (a, b) => a.news.length - b.news.length,
            render: (text) => (
                text.length > 100 ? `${text.substring(0, 100)}...` : text
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link className="confirm-text p-2" style={{ marginRight: "5px" }} to="#" onClick={() => handleEdit(record)}>
                            <i className="feather-edit-3" />
                        </Link>
                        {(role === "super admin" || (role === "user" && permissions.includes("delete_news"))) && (
                            <Link className="confirm-text p-2 ml-2" to="#" onClick={() => deleteNews(record.id)}>
                                <Trash2 className="feather-trash-2" />
                            </Link>
                        )}
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="page-wrapper">
            {loading ? <Loader /> : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Latest News Management</h4>
                                <h6>Manage News</h6>
                            </div>
                        </div>
                        <div className="page-btn">
                            {(role === "user" && permissions.includes("add_news")) || role === "super admin" ? (
                                <button onClick={handleShow} className="btn btn-added">
                                    <PlusCircle className="me-2" />
                                    Add New
                                </button>
                            ) : ""}
                        </div>
                    </div>

                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                        />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <Table
                                    className="table table-hover table-center mb-0 datatable"
                                    columns={columns}
                                    dataSource={newsList}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Add/Update News Modal */}
                    <Modal show={show} onHide={handleClose} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title>{edit ? "Update News" : "Add New News"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="p-2" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>News</label>
                                    <textarea
                                        value={news}
                                        onChange={(e) => setNews(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter news content"
                                        rows={5}
                                    />
                                </div>
                                <div style={{ padding: "10px 0px" }}>
                                    <ReCAPTCHA
                                        sitekey={SITE_KEY} // Replace with your reCAPTCHA site key
                                        onChange={handleCaptcha}
                                    />
                                </div>
                                <div className="d-flex justify-content-center gap-3 mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        {edit ? "Update" : "Save"}
                                    </button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default LatestNews;
