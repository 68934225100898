import React, { useEffect, useState, useRef } from "react";
import { Search, Edit, Trash2, Upload } from "react-feather";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import { PostApi } from "../../ApiClient/PostApi";
import Loader from "../../Utils/Loader";
import formatDate from "../../Utils/DateFormat";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../Utils/urls";

const ConstituencyManagement = () => {
  const [role, setRole] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [constituencyData, setConstituencyData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // New state for filtered data
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const fileInputRef = useRef(null); // Ref for file input
  const [captchaToken, setCaptchaToken] = useState(null);
  const handleCaptcha = (value) => {
      setCaptchaToken(value);
  };

  const { apiData, reFetch } = useFetch("getAllConsistuencies");
  const districts = useFetch("getAllDistrictsForConsistuency");

  useEffect(() => {
    setRole(localStorage.getItem("role"));
    setPermissions(JSON.parse(localStorage.getItem("permissions")));
    if (apiData?.data?.consistuencies) {
      let transformedData = apiData.data.consistuencies.map((constituency) => ({
        key: constituency?.id,
        areaName: constituency?.areaName ?? '',
        totalPollingStations: constituency?.totalPollingStations ?? 0,
        totalVoters: constituency?.totalVoters ?? 0,
        areaCode: constituency?.areaCode ?? '',
        ConstituencyName: constituency?.name?.trim() ?? '',
        DistrictName: constituency?.district?.name ?? 'No Data',
        RegionName: constituency?.district?.region?.name ?? 'No Data',
        CreatedDate: formatDate(constituency?.createdAt),
      }));
    
      setConstituencyData(transformedData);
      setFilteredData(transformedData); // Initialize filtered data
    }
    
  }, [apiData, role, permissions]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setEdit(false);
    setShow(true);
    setName("");
    setAreaCode("");
    setAreaName("");
    setId("");
    setDistrictId("");
  };

  const deleteConstituency = async (id) => {
    setLoading(true);
    const response = await PostApi("deleteConstituency", { id });
    if (response.data.status === "1") {
      reFetch();
      setLoading(false);
      setShow(false);
      success_toaster(response.data.message);
    } else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  const editModal = (obj) => {
    setName(obj.ConstituencyName);
    setAreaCode(obj.areaCode);
    setAreaName(obj.areaName);
    setId(obj.key);
    setDistrictId(obj.DistrictName);
    setShow(true);
    setEdit(true);
  };

  const updateConstituency = async () => {
    setLoading(true);
    const response = await PostApi("updateConstituency", {
      id: id,
      name: name,
      areaName: areaName,
      areaCode: areaCode,
      districtId: districtId,
    });
    if (response.data.status === "1") {
      reFetch();
      success_toaster(response.data.message);
      setLoading(false);
      setShow(false);
    } else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  const addConstituency = async (e) => {
    e.preventDefault();
    if (name === "" || areaCode === "" || areaName === "") {
      error_toaster("Fields is required!");
      return false;
    }
    if (districtId === "") {
      error_toaster("Please select District!");
      return false;
    }
    if (!captchaToken) {
      error_toaster("Please complete the reCAPTCHA.");
      return;
  }
    setLoading(true);
    const response = await PostApi("addConsistuency", {
      name: name,
      areaName,
      captcha: captchaToken,
      areaCode,
      districtId: districtId,
    });
    if (response.data.status === "1") {
      reFetch();
      setLoading(false);
      setShow(false);
      success_toaster(response.data.message);
    } else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      error_toaster("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);
    const response = await PostApi("addConsistuenciesFromExcel", formData, true);
    if (response.data.status === "1") {
      reFetch();
      setLoading(false);
      success_toaster(response.data.message);
    } else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  // Search functionality
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = constituencyData.filter((constituency) => {
      // Use match for case-insensitive matching on multiple fields
      const regex = new RegExp(query, "i");
      return (
        constituency.ConstituencyName.match(regex) ||
        constituency.areaName.match(regex) ||
        constituency.areaCode.match(regex)
      );
    });

    setFilteredData(filtered); // Update filtered data
  };

  const columns = [
    {
      title: "Constituency Name",
      dataIndex: "ConstituencyName",
      sorter: (a, b) => a.ConstituencyName.length - b.ConstituencyName.length,
      render: (status) => <span className="btn btn-warning btn-sm" style={{ width: "140px" }}>{status}</span>,
    },
    {
      title: "EA Name",
      dataIndex: "areaName",
      sorter: (a, b) => a.ConstituencyName.length - b.ConstituencyName.length,
      render: (status) => <span className="btn btn-info btn-sm" style={{ width: "120px" }}>{status}</span>,
    },
    {
      title: "EA Code",
      dataIndex: "areaCode",
      sorter: (a, b) => a.ConstituencyName.length - b.ConstituencyName.length,
      render: (status) => <span className="btn btn-success btn-sm" style={{ width: "120px" }}>{status}</span>,
    },
    {
      title: "No. of Poll Stations",
      dataIndex: "totalPollingStations",
      sorter: (a, b) => a.ConstituencyName.length - b.ConstituencyName.length,
      render: (status) => <span className="btn btn-success btn-sm" style={{ width: "120px" }}>{status}</span>,
    },
    {
      title: "No. of Voters",
      dataIndex: "totalVoters",
      sorter: (a, b) => a.ConstituencyName.length - b.ConstituencyName.length,
      render: (status) => <span className="btn btn-secondary btn-sm" style={{ width: "120px" }}>{status}</span>,
    },
    {
      title: "District Name",
      dataIndex: "DistrictName",
      sorter: (a, b) => a.DistrictName.length - b.DistrictName.length,
    },
    {
      title: "Region Name",
      dataIndex: "RegionName",
      sorter: (a, b) => a.RegionName.length - b.RegionName.length,
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {role === "user" && permissions.includes("edit_consistuency") ? (
              <Link className="me-2 p-2">
                <Edit onClick={() => editModal(record)} className="feather-edit" />
              </Link>
            ) : role === "super admin" ? (
              <Link className="me-2 p-2">
                <Edit onClick={() => editModal(record)} className="feather-edit" />
              </Link>
            ) : (
              ""
            )}

            {role === "user" && permissions.includes("delete_consistuency") ? (
              <Link className="confirm-text p-2" to="#" onClick={() => deleteConstituency(record.key)}>
                <Trash2 className="feather-trash-2" />
              </Link>
            ) : role === "super admin" ? (
              <Link className="confirm-text p-2" to="#" onClick={() => deleteConstituency(record.key)}>
                <Trash2 className="feather-trash-2" />
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Constituency Management</h4>
                <h6>Manage Constituencies</h6>
              </div>
            </div>
            <ul className="table-top-head">
              {role === "super admin" ? (
                <li>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="upload-tooltip">Upload</Tooltip>}>
                    <button className="btn btn-link" onClick={handleUploadClick}>
                      <Upload />
                    </button>
                  </OverlayTrigger>
                </li>
              ) : (
                ""
              )}
            </ul>
            <div className="page-btn">
              {role === "super admin" ? (
                <button onClick={handleShow} className="btn btn-added">
                  <PlusCircle className="me-2" />
                  Add New
                </button>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* Constituency list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={searchQuery} // Bind to searchQuery state
                      onChange={handleSearchChange} // Add onChange handler for search
                    />
                    <Link to="#" className="btn btn-searchset">
                      <Search className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* Filter */}
              <div className={`card`} id="filter_inputs">
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-lg col-sm-6 col-12"></div>
                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Enter Constituency Name"
                              className="form-control"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Enter District Name"
                              className="form-control"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                          <div className="form-group">
                            <Link className="btn btn-filters ms-auto" to="#">
                              <img src="assets/img/icons/searchwhites.svg" alt="img" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Constituency Table */}
              <div className="table-responsive">
                <Table
                  className="table  table-hover table-center mb-0 datatable"
                  columns={columns}
                  dataSource={filteredData} // Use filteredData instead of original data
                  rowKey={(record) => record.key}
                />
              </div>
            </div>
          </div>
          {/* Hidden file input */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
          {/* Add Constituency Modal */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{edit ? "Update Constituency" : "Add New Constituency"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="p-2">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="form-group">
                  <label>Area Name</label>
                  <input
                    value={areaName}
                    onChange={(e) => setAreaName(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Area Name"
                  />
                </div>
                <div className="form-group">
                  <label>Area Code</label>
                  <input
                    value={areaCode}
                    onChange={(e) => setAreaCode(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Area Code"
                  />
                </div>
                <div className="form-group">
                  <label>District</label>
                  <select
                    value={districtId}
                    onChange={(e) => setDistrictId(e.target.value)}
                    className="form-control"
                  >
                    <option value="">Select District</option>
                    {districts?.apiData?.data?.districts?.map((dat) => (
                      <option key={dat.id} value={dat.id}>
                        {dat.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ padding: "10px 0px" }}>
                                    <ReCAPTCHA
                                        sitekey={SITE_KEY} // Replace with your reCAPTCHA site key
                                        onChange={handleCaptcha}
                                    />
                                </div>
                {loading ? (
                  <div className="d-flex justify-content-center align-content-center">
                    <Loader />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center gap-3 mt-3">
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                      Close
                    </button>
                    {edit ? (
                      <button onClick={updateConstituency} type="submit" className="btn btn-primary">
                        Update
                      </button>
                    ) : (
                      <button onClick={addConstituency} type="submit" className="btn btn-primary">
                        Save
                      </button>
                    )}
                  </div>
                )}
              </form>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ConstituencyManagement;
