import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import Table from "../../core/pagination/datatable";
import { error_toaster } from "../../Utils/Toaster"; // Assuming success_toaster is needed
import Loader from "../../Utils/Loader";
import jsPDF from "jspdf";
import "jspdf-autotable";

const NeckReport = () => {
    const [partyId, setPartyId] = useState(null);
    const [list, setList] = useState([]); // New state to store fetched data
    const [loading, setLoading] = useState(false);
    const getAllParties = useFetch("getAllParties");

    const submit = async () => {
        try {
            setLoading(true);
            let response = await PostApi("neckReport", { partyId });

            if (response.data.status === "1" && response.data) {
                // Assuming the response structure matches your example
                const formattedData = response?.data?.data?.map(item => ({
                    regionName: item.region,
                    totalConstituencies: 1, // Assuming each row represents a single constituency
                    constituencyName: item.constituency,
                    winner: `${item.winnerCandidate} (${item.winnerParty})`,
                    winnerPercentage: item.winnerVotePercentage,
                    runnerUp: `${item.runnerUpCandidate} (${item.runnerUpParty})`,
                    runnerUpPercentage: item.runnerUpVotePercentage,
                    voteDifference: item.voteDifference
                }));

                setList(formattedData);
                // success_toaster("Data fetched successfully!");
            } else {
                error_toaster("No data available or something went wrong");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            error_toaster("Something went wrong");
            console.error("Error in submit function:", error);
        }
    };

    const columns = [
        {
            title: "Region Name",
            dataIndex: "regionName",
            sorter: (a, b) => a.regionName.localeCompare(b.regionName),
        },
        {
            title: "Total Constituencies",
            dataIndex: "totalConstituencies",
            sorter: (a, b) => a.totalConstituencies - b.totalConstituencies,
        },
        {
            title: "Constituency Name",
            dataIndex: "constituencyName",
            sorter: (a, b) => a.constituencyName.localeCompare(b.constituencyName),
        },
        {
            title: "Winner",
            dataIndex: "winner",
        },
        {
            title: "Winner (%)",
            dataIndex: "winnerPercentage",
        },
        {
            title: "Runner-Up",
            dataIndex: "runnerUp",
        },
        {
            title: "Runner-Up (%)",
            dataIndex: "runnerUpPercentage",
        },
        {
            title: "Vote Difference",
            dataIndex: "voteDifference",
            sorter: (a, b) => a.voteDifference - b.voteDifference,
        },
    ];
console.log(JSON.stringify(list))
const handleExportPDF = () => {
    const doc = new jsPDF();
    const verticalMargin = 20; // Set the top vertical margin

    // Get current date
    const currentDate = new Date().toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    // Set PDF title
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Neck to Neck Summary", 14, verticalMargin);

    // Add current date to the corner
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(
        currentDate,
        doc.internal.pageSize.width - 30,
        verticalMargin,
        null,
        null,
        "right"
    );

    // Region and Constituency Information
    if (list && list.length > 0) {
        // Define the table headers
        const tableHeaders = [
            "Region Name",
            "Total Constituencies",
            "Constituency Name",
            "Winner",
            "Winner (%)",
            "Runner-Up",
            "Runner-Up (%)",
            "Vote Difference",
        ];

        // Constituency Table Data
        const tableData = list.map((constituency) => [
            constituency.regionName,
            constituency.totalConstituencies,
            constituency.constituencyName,
            constituency.winner,
            constituency.winnerPercentage,
            constituency.runnerUp,
            constituency.runnerUpPercentage,
            constituency.voteDifference,
        ]);

        // Draw the table
        doc.autoTable({
            head: [tableHeaders],
            body: tableData,
            startY: verticalMargin + 20,
            theme: "striped",
        });
    } else {
        doc.setFont("helvetica", "bold");
        doc.text("Information Not Available", 14, verticalMargin + 20);
    }

    // Save the PDF
    doc.save("neck_to_neck_report.pdf");
};

    return (
        <div className="page-wrapper">
            {
                loading ? <Loader /> : <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Neck to Neck Report</h4>
                                
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <button
                            className="btn btn-success"
                            style={{ marginBottom: "5px" }}
                            onClick={handleExportPDF}
                        >
                            Export to PDF
                        </button>
                    </div>


                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Select Party</label>
                                        <select
                                            name="party"
                                            value={partyId}
                                            onChange={(e) => setPartyId(e.target.value)}
                                            className="form-control"
                                        >
                                            <option value="">Select Party</option>
                                            {getAllParties?.apiData?.data?.parties?.map((party) => (
                                                <option key={party.id} value={party.id}>
                                                    {party.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button onClick={submit} className="btn btn-primary mt-3">
                                Submit
                            </button>
                        </div>
                    </div>

                    {/* Table Section */}
                    <div className="card mb-4 shadow my-3">
                        <div className="card-body">
                            <h5 className="card-title">Summary</h5>
                            {/* Render table with data */}
                            <Table
                                columns={columns}
                                dataSource={list} // Data from the state
                                pagination={{ pageSize: 5 }} // Optional pagination
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default NeckReport;
