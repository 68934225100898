import React, { useEffect, useState } from "react";
import { File, User, UserCheck } from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import Chart from "react-apexcharts"; // Import the Chart component
import Loader from "../../Utils/Loader";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import { Modal } from "react-bootstrap";
import { FaStar } from "react-icons/fa";

const AgentDashboard = () => {
    const [userId, setUserId] = useState("");
    const [partyId, setPartyId] = useState("");
    const [type, setType] = useState("");
    const [loading, setLoading] = useState(false);
    const [candidates, setCandidates] = useState([]);
    const [constId, setConstId] = useState("");
    const [rating, setRating] = useState(0); // Selected rating
    const [hover, setHover] = useState(null); // Hover effect for stars
    const [feedback, setFeedback] = useState(""); // User feedback text
    const [review, setReview] = useState(null);

    const [chartOptions, setChartOptions] = useState({
        chart: {
            id: "basic-bar"
        },
        xaxis: {
            categories: []
        },
        yaxis: {
            max: 300 // Initial max value
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%', // Set a fixed bar width percentage
                barHeight: '50%', // You can use this if you're doing a horizontal bar chart, otherwise it can be omitted
                borderRadius: 5, // Adds some rounding to the bars to make them look nicer
            }
        },
        colors: [] // Set colors based on type
    });

    const [chartSeries, setChartSeries] = useState([]);

    // Get user ID and party ID from local storage
    useEffect(() => {
        const userId = localStorage.getItem("userId");
        const partyId = localStorage.getItem("partyId");
        const review = localStorage.getItem("review") === true; // Convert string to boolean
    
        setUserId(userId || null); // Default to null if not found
        setPartyId(partyId || null);
        setReview(!review); // Set to false if true, and true otherwise
    }, [setUserId, setPartyId, setReview]);
    
    

    // Fetch dashboard data
    const { apiData } = useFetch(`agentDashboard/${userId}`);

    // Handle polling station selection
    const selectedPollingStation = async (id) => {
        if (type === "") {
            error_toaster("Please select candidate type!");
            return false;
        }
        setLoading(true);
        setConstId(id);
        const response = await PostApi("pollingStationGraphData", { id: id, partyId, type: type });
        console.log("Polling Station Graph Data: ", JSON.stringify(response));

        // Handle candidates data from the API response
        const formattedCandidates = response?.data?.data?.formattedCandidates || [];
        setCandidates(formattedCandidates);
        setLoading(false);
    };

    // Update chart based on candidates data
    useEffect(() => {
        if (candidates.length === 0) return;
    
        const categories = [];
        const votes = [];
        let maxVote = 150;
    
        candidates.forEach(candidate => {
            const candidateName = candidate?.name || "Unknown Candidate";
            categories.push(candidateName);
            const vote = candidate?.data?.[0]?.y || 0;
            votes.push(vote);
    
            if (vote > maxVote) {
                maxVote = vote;
            }
        });
    
        const fontSize = categories.length > 10 ? '8px' : '10px';
    
        setChartOptions(prevOptions => ({
            ...prevOptions,
            chart: {
                ...prevOptions.chart,
                height: 500, // Increase chart height to give more space for labels
            },
            xaxis: {
                categories,
                labels: {
                    style: {
                        fontSize,
                        whiteSpace: 'normal',
                    },
                    rotate: -45, // Rotate labels at -45 degrees for better readability
                    rotateAlways: true, // Forces rotation to show all labels
                    maxLines: 3,
                    offsetY: 10, // Adjust vertical offset
                    trim: false, // Prevents truncation of long labels
                },
                tickPlacement: 'on', // Ensures labels are centered below the bars
                labelsStaggered: true, // Stagger labels to prevent overlapping
            },
            tooltip: {
                enabled: true, // Enables tooltips to show the full name on hover
                y: {
                    formatter: (value) => `Votes: ${value}`, // Customize tooltip to show votes
                }
            },
            yaxis: { 
                ...prevOptions.yaxis, 
                max: maxVote + 50 
            },
            plotOptions: {
                bar: {
                    columnWidth: '40%',
                    borderRadius: 5,
                }
            },
            colors: type === "Presidential" ? ["#EA5455"] : ["#28C76F"],
        }));
    
        setChartSeries([{
            name: type,
            data: votes,
        }]);
    
    }, [candidates, type]);
    
    const handleClose =  () => setReview(false);
    const handleSave =async (e) => {
        e.preventDefault();
        let response = await PostApi("addRating",{rating:rating,feedback:feedback,userId : userId});
        if(response.data.status == "1"){
            setReview(false);
            success_toaster(response.data.message)
        }
        else{
            error_toaster(response.data.message)
        }
      };
    
    

    // Reset chart data when type changes
    useEffect(() => {
        setCandidates([]); // Reset candidates
        setChartSeries([]); // Reset chart series
        setChartOptions(prevOptions => ({
            ...prevOptions,
            xaxis: { categories: [] }, // Reset x-axis categories
            yaxis: { ...prevOptions.yaxis, max: 300 }, // Reset y-axis max
            colors: [], // Reset colors
        }));
    }, [type]); // Run when the type changes

    return (
        <div>
            <div className="page-wrapper">
                {loading ? <Loader /> : <div className="content">
                    <div className="row">
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-count">
                                <div className="dash-counts">
                                    <h4>{apiData?.data?.constituencyCount || "0"}</h4>
                                    <h5>No. of Constituencies</h5>
                                </div>
                                <div className="dash-imgs">
                                    <User />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-count das1">
                                <div className="dash-counts">
                                    <h4>{apiData?.data?.pollingstationCount || "0"}</h4>
                                    <h5>No. of Polling Stations</h5>
                                </div>
                                <div className="dash-imgs">
                                    <UserCheck />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-count das2">
                                <div className="dash-counts">
                                    <h4>{apiData?.data?.presidentialCandidates || "0"}</h4>
                                    <h5>Presidential Candidates</h5>
                                </div>
                                <div className="dash-imgs">
                                    <ImageWithBasePath
                                        src="assets/img/icons/file-text-icon-01.svg"
                                        className="img-fluid"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12 d-flex">
                            <div className="dash-count das3">
                                <div className="dash-counts">
                                    <h4>{apiData?.data?.parlimentaryCandidates || "0"}</h4>
                                    <h5>Parliamentary Candidates</h5>
                                </div>
                                <div className="dash-imgs">
                                    <File />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-sm-12 col-12 d-flex">
                            <div className="card flex-fill">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="card-title mb-0">Polling Station Report</h5>
                                    <div className="graph-sets">
                                        <div className="dropdown dropdown-wraper" style={{ margin: "0px 5px" }}>
                                            <select
                                                defaultValue={type}
                                                onChange={(e) => setType(e.target.value)}
                                                className="form-control bg-warning text-white"
                                            >
                                                <option value="">Select Candidate Type</option>
                                                <option value="Presidential">Presidential</option>
                                                <option value="Parliamentary">Parliamentary</option>
                                            </select>
                                        </div>
                                        <div className="dropdown dropdown-wraper">
                                            <select
                                                defaultValue={constId}
                                                onChange={(e) => selectedPollingStation(e.target.value)}
                                                className="form-control bg-info text-white"
                                            >
                                                <option>Select Polling Stations</option>
                                                {apiData?.data?.pollingstations?.map((cons) => (
                                                    <option
                                                        key={cons?.pollingstation?.id}
                                                        value={cons?.pollingstation?.id}
                                                    >
                                                        {cons?.pollingstation?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Chart
                                        options={chartOptions} // Chart configuration
                                        series={chartSeries} // Data for the chart
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <Modal show={review} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Rating and Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        {/* Star Rating */}
                        <div className="form-group">
                            <label>Rating</label>
                            <div>
                                {[1, 2, 3, 4, 5].map((star) => (
                                    <FaStar
                                        key={star}
                                        size={24}
                                        onClick={() => setRating(star)}
                                        onMouseEnter={() => setHover(star)}
                                        onMouseLeave={() => setHover(null)}
                                        color={star <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                                        style={{ cursor: "pointer", marginRight: "5px" }}
                                    />
                                ))}
                            </div>
                        </div>

                        {/* Feedback Input */}
                        <div className="form-group mt-3">
                            <label>Review</label>
                            <input
                                type="text"
                                className="form-control"
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                            />
                        </div>

                        {/* Action Buttons */}
                        <div className="d-flex justify-content-center gap-3 mt-3">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AgentDashboard;
