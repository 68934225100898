import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import useFetch from "../../ApiClient/GetApi";
import Loader from "../../Utils/Loader";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster } from "../../Utils/Toaster";
import Chart from "react-apexcharts";
import { IMAGE_URL } from "../../Utils/urls";
import { toWords } from 'number-to-words';
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

ChartJS.register(ArcElement, ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const Reports = () => {
    const [stationId, setStationId] = useState(null);
    const [partyId, setPartyId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [list, setList] = useState([]);

    const { apiData } = useFetch("getPollingStations");
    const getAllParties = useFetch("getAllParties");

    const submit = async () => {
        if (!type || !partyId || !stationId) {
            error_toaster("All Fields are required!");
            return false;
        }
        setLoading(true);

        try {
            let response = await PostApi("submitReport", { partyId, type: type, stationId: stationId });

            if (response.data?.status === "1") {
                console.log(response.data.data)
                if (response?.data?.data?.data?.data?.pollingStationCandidates?.length == 0) {
                    error_toaster("No Result uploaded yet!");
                    return false;
                }
                const reportResponseData = response?.data?.data?.data?.data;  // Adjusted to extract nested data

                if (reportResponseData?.resultData == null) {
                    error_toaster("Sorry! Result not uploaded yet");
                    setLoading(false);
                    return false;
                }

                // Set the required data to state
                setList(reportResponseData.chartData);
                setReportData(reportResponseData); // Save the entire report data to the state
            }
        } catch (error) {
            error_toaster("Error fetching report data");
        } finally {
            setLoading(false);
        }
    };

    const calculatePercentage = (securedVotes, totalVoters) => {
        return ((securedVotes / totalVoters) * 100).toFixed(2);
    };
    console.log(JSON.stringify(list))
    const calculateValidVotes = () => {
        if (!reportData || !reportData.pollingStationCandidates) return 0;

        return reportData.pollingStationCandidates.reduce((sum, candidateData) => {
            const { voteSecured } = candidateData;
            const secured = voteSecured !== null ? parseInt(voteSecured) : 0;
            return sum + secured;
        }, 0);
    };

    const getWinningCandidate = () => {
        if (!reportData || !reportData.pollingStationCandidates) return null;
        const winningCandidate = reportData.pollingStationCandidates.reduce((max, candidate) => (parseInt(candidate.voteSecured) > parseInt(max.voteSecured) ? candidate : max), reportData.pollingStationCandidates[0]);
        return winningCandidate;
    };

    const winningCandidate = getWinningCandidate();

    const [chartOptions, setChartOptions] = useState({
        series: [{ name: "Votes", data: [] }],
        colors: ["#28C76F", "#EA5455"],
        chart: {
            type: "bar",
            height: 320,
            stacked: true,
            zoom: { enabled: true },
        },
        responsive: [
            {
                breakpoint: 280,
                options: {
                    legend: {
                        position: "bottom",
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 4,
                borderRadiusApplication: "end",
                borderRadiusWhenStacked: "all",
                columnWidth: "20%",
            },
        },
        dataLabels: { enabled: false },
        yaxis: { min: 0, max: 300, tickAmount: 5 },
        xaxis: { categories: [] },
        legend: { show: false },
        fill: { opacity: 1 },
    });


    useEffect(() => {
        if (list && list.length > 0) {
            // Prepare separate series for each party
            const categories = [];
            const presidentialVotes = [];
            const parliamentaryVotes = [];

            let maxVote = 0; // Track the maximum votes to adjust y-axis scaling

            // Loop through the list and extract data for each series
            list.forEach((party) => {
                categories.push(party.name); // Add party name as a category

                // Extract votes based on type
                const presidentialVote = parseInt(party.data.find((vote) => vote.name === "Presidential")?.y || 0, 10);
                const parliamentaryVote = parseInt(party.data.find((vote) => vote.name === "Parliamentary")?.y || 0, 10);

                presidentialVotes.push(presidentialVote);
                parliamentaryVotes.push(parliamentaryVote);

                // Update maxVote to find the highest value
                maxVote = Math.max(maxVote, presidentialVote, parliamentaryVote);
            });

            // Define series data based on type
            const selectedSeries = [
                {
                    name: "Presidential",
                    data: presidentialVotes,
                },
                {
                    name: "Parliamentary",
                    data: parliamentaryVotes,
                }
            ];

            // Set chart options dynamically
            setChartOptions((prevOptions) => ({
                ...prevOptions,
                series: selectedSeries, // Update series to have both Presidential and Parliamentary votes
                xaxis: { categories },  // Set categories for x-axis
                yaxis: { ...prevOptions.yaxis, max: maxVote + 50 }, // Adjust y-axis based on max votes
            }));
        }
    }, [list, type]);


    const stationOptions = apiData?.data?.stations?.map((station) => ({
        value: station.id,
        label: `${station.name} - ${station.code}`,
    })) || [];

    console.log(JSON.stringify(reportData))

    const handleExportPollingStationPDF = () => {
        const doc = new jsPDF();

        // Get current date
        const currentDate = new Date().toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        // Set PDF title
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text(`Polling Station Data Report`, 14, 10);
        doc.setFontSize(10);
        doc.text(`Candidate Type: ${type}`, 14, 18);  // Assuming it's always "Presidential"

        // Polling Station Information
        doc.setFontSize(10);
        doc.text(`Polling Station: ${reportData.dd.name || 'N/A'}`, 14, 24);
        doc.text(`Polling Station Code: ${reportData.dd.code || 'N/A'}`, 14, 28);

        // Add current date to the corner
        doc.setFontSize(10);
        doc.setTextColor(100);
        doc.text(currentDate, doc.internal.pageSize.width - 30, 10, null, null, 'right');

        // Total Voters and Votes Cast information
        doc.setFont("helvetica", "normal");
        doc.text(`Total Voters: ${reportData.dd.totalVoters || 'N/A'}`, 14, 36);

        // Calculate Votes Cast
        let votesCast = 0;

        // Sum up voteSecured for each candidate
        if (Array.isArray(reportData.pollingStationCandidates)) {
            reportData.pollingStationCandidates.forEach(candidate => {
                if (candidate.voteSecured) {
                    votesCast += parseInt(candidate.voteSecured);
                }
            });
        }

        // Add Wrong Votes
        let wrongVotes = parseInt(reportData.resultData.wrongVotes) || 0;
        votesCast += wrongVotes;

        doc.text(`Votes Cast: ${votesCast}`, 14, 40);

        // Candidates and Vote Secured Table
        if (Array.isArray(reportData.pollingStationCandidates) && reportData.pollingStationCandidates.length > 0) {
            const candidateData = reportData.pollingStationCandidates.map(candidate => ({
                candidateName: candidate.candidate.name || 'N/A',
                partyName: candidate.candidate.party.name || 'N/A',
                candidateType: candidate.candidate.candidatetype.name || 'N/A',
                voteSecured: candidate.voteSecured || 'N/A'
            }));

            // Prepare candidate table data
            const candidatesTable = [];
            candidateData.forEach(candidate => {
                candidatesTable.push([candidate.candidateName, candidate.partyName, candidate.candidateType, candidate.voteSecured]);
            });

            // Add Candidates Table to PDF
            doc.autoTable({
                startY: 50,
                head: [['Candidate Name', 'Party Name', 'Candidate Type', 'Votes Secured']],
                body: candidatesTable,
                theme: 'striped',
                styles: { overflow: 'linebreak' },
                columnStyles: {
                    0: { cellWidth: 'auto' },
                    1: { cellWidth: 'auto' },
                    2: { cellWidth: 'auto' },
                    3: { cellWidth: 'auto' }
                }
            });
        } else {
            doc.setFont("helvetica", "bold");
            doc.text("No Candidate Information Available", 14, 50);
        }

        // Save the PDF
        doc.save("polling_station_data_report.pdf");
    };


    return (
        <div className="page-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Reports & Analytics</h4>
                                <h6>Generate and View Reports</h6>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <button className="btn btn-success" style={{marginBottom:"5px"}} onClick={handleExportPollingStationPDF}>
                            Export to PDF
                        </button>
                    </div>

                    {/* Form for selecting region, district, etc */}
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Candidate Type</label>
                                        <select name="candidateType" value={type} onChange={(e) => setType(e.target.value)} className="form-control">
                                            <option value="">Select Candidate Type</option>
                                            <option value="Parliamentary">Parliamentary</option>
                                            <option value="Presidential">Presidential</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Select Party</label>
                                        <select name="partyId" value={partyId} onChange={(e) => setPartyId(e.target.value)} className="form-control">
                                            <option value="">Select Party</option>
                                            {getAllParties?.apiData?.data?.parties?.map((party) => (
                                                <option key={party.id} value={party.id}>{party.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Polling Station</label>
                                        <Select
                                            options={stationOptions}
                                            value={stationOptions.find(option => option.value === stationId)}
                                            onChange={(option) => setStationId(option.value)}
                                            isSearchable={true}
                                            placeholder="Select Polling Station"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button onClick={submit} className="btn btn-primary mt-3">Submit</button>
                        </div>
                    </div>

                    {/* Show polling station details in button-style cards */}
                    {reportData && (
                        <div className="row mb-4">
                            <div className="col-md-3">
                                <div className="card text-white bg-warning mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Polling Station</h5>
                                        <p className="card-text">{reportData.dd?.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card text-white bg-info mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Total Voters</h5>
                                        <p className="card-text">{reportData.dd?.totalVoters}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card text-white bg-secondary mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Constituency</h5>
                                        <p className="card-text">{reportData?.dd?.constituency?.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card text-white bg-success mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">District</h5>
                                        <p className="card-text">{reportData?.dd?.constituency?.district?.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card text-white bg-warning mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Region</h5>
                                        <p className="card-text">{reportData?.dd?.constituency?.district?.region?.name}</p>
                                    </div>
                                </div>
                            </div>
                            {winningCandidate && (
                                <>
                                    <div className="col-md-3">
                                        <div className="card text-white bg-info mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title">Winner Candidate</h5>
                                                <p className="card-text">{winningCandidate.candidate.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card text-white bg-secondary mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title">Winner Votes</h5>
                                                <p className="card-text">{winningCandidate.voteSecured}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card text-white bg-success mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title">Winner Party</h5>
                                                <p className="card-text">{winningCandidate.candidate.party.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}

                    {/* Display candidate data */}
                    {reportData && (
                        <div>
                            <div className="card shadow">
                                <div className="card-body">
                                    <table className="table table-striped table-bordered">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>Candidate Name</th>
                                                <th>Party</th>
                                                <th>No. Votes Secured (In Figures)</th>
                                                <th>No. Votes Secured (In Words)</th>
                                                <th>% of Votes Secured</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportData.pollingStationCandidates.map((candidateData) => {
                                                const { candidate, voteSecured } = candidateData;
                                                return (
                                                    <tr key={candidate.id}>
                                                        <td>{candidate.name}</td>
                                                        <td>
                                                            <div>
                                                                <img src={IMAGE_URL + candidate?.party?.image} style={{ height: "40px", width: "40px", objectFit: "contain" }} alt={candidate?.party?.name} />
                                                            </div>
                                                        </td>
                                                        <td>{voteSecured ? voteSecured : 0}</td>
                                                        <td>{toWords(voteSecured ?? 0)}</td>
                                                        <td>{calculatePercentage(voteSecured, calculateValidVotes())}%</td>
                                                    </tr>
                                                );
                                            })}
                                            <tr>
                                                <td colSpan="2">Total Valid Ballot</td>
                                                <td>{calculateValidVotes()}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">Total Rejected Ballot</td>
                                                <td>{reportData.resultData.wrongVotes}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">Total Ballot Cast</td>
                                                <td>{parseInt(calculateValidVotes()) + parseInt(reportData.resultData.wrongVotes)}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Chart */}
                    <div className="card-body bg-white shadow my-3">
                        {list?.length > 0 && (
                            <Chart
                                options={chartOptions}
                                series={chartOptions.series}
                                type="bar"
                                height={320}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Reports;
