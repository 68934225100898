import React, {  useMemo } from "react";
import { FaStar } from "react-icons/fa";
import useFetch from "../../ApiClient/GetApi";

export default function RatingManagement() {
  const { apiData } = useFetch("getAllRatings");

  // Separate data into Controller and Agent sections
  const separatedRatings = useMemo(() => {
    const controllers = [];
    const agents = [];

    if (apiData?.data) {
      apiData.data.forEach((rating) => {
        if (rating.role.name === "controller") controllers.push(rating);
        if (rating.role.name === "agent") agents.push(rating);
      });
    }

    return { controllers, agents };
  }, [apiData]);

  const renderStars = (rating) => {
    return (
      <div>
        {[1, 2, 3, 4, 5].map((star) => (
          <FaStar
            key={star}
            size={20}
            color={star <= rating ? "#ffc107" : "#e4e5e9"}
          />
        ))}
      </div>
    );
  };

  const renderRatingsTable = (data) => (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
               
                <th>Rating</th>
                <th>Comment</th>
                
              </tr>
            </thead>
            <tbody>
              {data.map((rating) => (
                <tr key={rating.id}>
                  <td>{rating.user.name}</td>
                
                  <td>{renderStars(rating.rating)}</td>
                  <td>{rating.comment}</td>
                 
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <h4>Rating Management</h4>
          <h6>Manage Ratings by Controllers and Agents</h6>
        </div>

        <div className="section">
          <h5>Controller Ratings</h5>
          {separatedRatings.controllers.length > 0 ? (
            renderRatingsTable(separatedRatings.controllers)
          ) : (
            <p>No ratings found for controllers.</p>
          )}
        </div>

        <div className="section mt-4">
          <h5>Agent Ratings</h5>
          {separatedRatings.agents.length > 0 ? (
            renderRatingsTable(separatedRatings.agents)
          ) : (
            <p>No ratings found for agents.</p>
          )}
        </div>
      </div>
    </div>
  );
}
