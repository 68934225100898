import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useFetch from "../../ApiClient/GetApi";
import Table from "../../core/pagination/datatable"; // Importing Table
import { Search } from "feather-icons-react/build/IconComponents";

const BallotDetails = () => {
    const location = useLocation();
    const { apiData } = useFetch(`ballotDetails/${location?.state?.id}`);
    const details = apiData?.data?.details;
    const statistics = apiData?.data?.statistics;

    const [searchTerm, setSearchTerm] = useState("");

    // Columns for the table
    const columns = [
        { title: "Question", dataIndex: "question", key: "question", render: (text) => <strong>{text}</strong> },
        { title: "Answer", dataIndex: "answer", key: "answer", render: (text) => text || "No Answer" },
        { title: "User Name", dataIndex: "name", key: "name", render: (text) => text || "N/A" },
        { title: "User Email", dataIndex: "email", key: "email", render: (text) => text || "N/A" },
        { title: "User Gender", dataIndex: "gender", key: "gender", render: (text) => text || "N/A" },
    ];

    // Preparing data for the table
    const dataSource = details?.questions?.flatMap((question, index) =>
        question.useranswers.map((userAnswer, answerIndex) => ({
            key: `${index}-${answerIndex}`,
            question: question.question || "No Question",
            answer: userAnswer.answer?.answer || "No Answer",
            name: userAnswer.userdatum?.name || "Anonymous",
            email: userAnswer.userdatum?.email || "No Email",
            gender: userAnswer.userdatum?.gender
                ? userAnswer.userdatum.gender.charAt(0).toUpperCase() + userAnswer.userdatum.gender.slice(1).toLowerCase()
                : "Not Specified",
        }))
    ) || [];

    // Filter data based on search term
    const filteredData = dataSource.filter(
        (item) =>
            item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.answer.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="page-title">
                        <h4>Ballot Management</h4>
                        <h6>Manage E-Ballots</h6>
                    </div>
                </div>

                {/* Ballot Details */}
                {details && (
                   <div className="statistics-section card" style={{ padding: "15px", backgroundColor: "#f9f9f9", margin: "10px 0px" }}>
                     <div  style={{ marginBottom: "15px", border: "1px solid #e0e0e0", borderRadius: "8px", padding: "10px", backgroundColor: "#ffffff" }}>
                        <h5>{details.ballotName}</h5>
                        <p style={{ margin: "20px 0px", textAlign: "justify" }}>{details.description}</p>
                        </div>
                    </div>
                )}

                {/* Statistics Section */}
                {statistics && (
                    <div className="statistics-section card" style={{ padding: "15px", backgroundColor: "#f9f9f9", margin: "10px 0px" }}>
                        <h5 style={{ borderBottom: "2px solid #dedede", paddingBottom: "8px", marginBottom: "15px" }}>Statistics</h5>
                        {Object.entries(statistics).map(([question, answers]) => (
                            <div key={question} style={{ marginBottom: "15px", border: "1px solid #e0e0e0", borderRadius: "8px", padding: "10px", backgroundColor: "#ffffff" }}>
                                <h6 style={{ color: "#333", fontWeight: "bold", borderBottom: "1px solid #ddd", paddingBottom: "5px", marginBottom: "10px" }}>{question}</h6>
                                <ul style={{ listStyleType: "none", padding: "0" }}>
                                    {Object.entries(answers).map(([answer, count]) => (
                                        <li key={answer} style={{ display: "flex", justifyContent: "space-between", padding: "5px 0", borderBottom: "1px solid #f1f1f1" }}>
                                            <span>{answer}</span>
                                            <span style={{ fontWeight: "bold", color: "#007bff" }}>{count} {count === 1 ? "User" : "Users"}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                )}

                {/* Questions and Answers Table */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control form-control-sm formsearch"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <Link to="#" className="btn btn-searchset">
                                        <Search className="feather-search" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Table columns={columns} dataSource={filteredData} rowKey="id" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BallotDetails;
