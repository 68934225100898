import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../Utils/Loader";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster } from "../../Utils/Toaster";
import Table from "../../core/pagination/datatable";
import formatDate from "../../Utils/DateFormat";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const AdminNationalReport = () => {
    const [partyId, setPartyId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [list, setList] = useState([]);
    const [pastResult, setPastResult] = useState([]);
    const [pastRecords, setPastRecords] = useState([]);
    const [headers, setHeaders] = useState([]);
    
    useEffect(()=>{
        setPartyId(localStorage.getItem("partyId"));
    },[])

    const submit = async () => {
        if (!type || !partyId) {
            error_toaster("All Fields are required!");
            return false;
        }
        setLoading(true);
        let response = await PostApi("nationalReport", { partyId, type });
        console.log(response)

        if (response.data && response.data.status === "1") {
            setReportData(response?.data?.data);
            setHeaders(response?.data?.data?.pastRecords?.headers || []);
            setPastRecords(response?.data?.data?.pastRecords?.rows || []);
            setLoading(false);
        } else {
            error_toaster("No Result found!");
            setLoading(false);
        }
    };

    // Helper function to check if a value is an array and is not empty
    const isArrayAndNotEmpty = (value) => Array.isArray(value) && value.length > 0;
    useEffect(() => {


        if (reportData) {
            const formattedData = reportData?.regions?.map((party) => ({
                name: party.regionName,
                totalDistricts: party.totalDistricts,
                totalConstituencies: party.totalConstituencies,
                totalVoters: party.totalVoters,
                totalPollingStations: party.totalPollingStations,
                totalVoteCast: party.totalVoteCast,

            }));
            setList(formattedData);
            const record = reportData?.pastResults?.map((party) => ({
                year: party.year,
                votes: party.votes,
                candidate: party.candidate,
                candidatetype: party?.candidatetype?.name,
                percentage: `${party.percentage}%` ?? "0",
                district: party.constituency?.district?.name,
                region: party?.region?.name,
                party: party?.party?.name,

                createdAt: formatDate(party.createdAt)

            }));

            setPastResult(record)
        }
    }, [reportData]);
    const pastRecordsColumns = headers?.map((header, index) => ({
        title: header,
        dataIndex: header.toLowerCase(),
        key: index,
    }));
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Total Districts",
            dataIndex: "totalDistricts",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Total Constituencies",
            dataIndex: "totalConstituencies",
        },
        {
            title: "Total P.S",
            dataIndex: "totalPollingStations",
        },
        {
            title: "Total Voters",
            dataIndex: "totalVoters",
        },
        {
            title: "Total Valid Votes Cast",
            dataIndex: "totalVoteCast",
        },


    ];
    const pastColumns = [
        {
            title: "Year",
            dataIndex: "year",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
        },
        {
            title: "Candidate",
            dataIndex: "candidate",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
            render: (status) => <span className="btn btn-warning btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "Candidate Type",
            dataIndex: "candidatetype",

        },
        {
            title: "Party",
            dataIndex: "party",
            sorter: (a, b) => a.PartyName.length - b.PartyName.length,
            render: (status) => <span className="btn btn-info btn-sm" style={{ width: "120px" }}>{status}</span>,
        },
        {
            title: "No. of Votes",
            dataIndex: "votes",
        },
        {
            title: "% of Votes",
            dataIndex: "percentage",


        },
        {
            title: "Region",
            dataIndex: "region",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        },

    ];
    const handleExportNationalReportPDF = () => {
        const doc = new jsPDF();
        const leftMargin = 20; // Left margin
        const topMargin = 20;  // Top margin
        const lineSpacing = 8; // Space between lines

        // Get current date
        const currentDate = new Date().toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        // Set PDF title
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text(`National Report Data`, leftMargin, topMargin);
        doc.setFontSize(10);
        doc.text(`Candidate Type: ${type || 'N/A'}`, leftMargin, topMargin + 6);
        doc.setFontSize(10);

        // Add current date to the top-right corner
        doc.setFontSize(10);
        doc.setTextColor(100);
        doc.text(currentDate, doc.internal.pageSize.width - leftMargin, topMargin, null, null, 'right');

        const totalPollingStations = reportData.counts.totalPollingStations; // Replace with actual total polling stations
        const stationsWithNoResults = reportData.counts.stationsWithNoResults;  // Replace with actual stations without results
        const stationsWithResults = totalPollingStations - stationsWithNoResults;
        const resultAnnouncedPercentage = (stationsWithResults / totalPollingStations) * 100;

        // Polling Station Information
        doc.setFont("helvetica", "normal");
        let currentY = topMargin + lineSpacing * 2; // Start position for the first line after title and candidate type
        doc.text(`Total Polling Stations: ${reportData.counts.totalPollingStations}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Polling Stations With Results: ${reportData.counts.totalPollingStations-reportData.counts.stationsWithNoResults}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Polling Stations With No Results: ${reportData.counts.stationsWithNoResults}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Polling Station Percentage: ${Number(resultAnnouncedPercentage).toFixed(2)}%`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Total Votes Cast: ${reportData.data.totalVotesCast}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Total Rejected Votes: ${reportData.data.totalWrongVotes}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Total Regions: ${reportData.regionCount}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Total Districts: ${reportData.districtCount}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Total Constituencies: ${reportData.constituencyCount}`, leftMargin, currentY);
        currentY += lineSpacing;

        // Candidates and Vote Secured Table
        if (Array.isArray(reportData.data.partyData) && reportData.data.partyData.length > 0) {
            const partyData = reportData.data.partyData.map(party => ({
                partyName: party.partyName || 'N/A',
                totalVotesSecured: party.totalVotesSecured || 'N/A',
                constituenciesWon: party.constituenciesWon || 'N/A',
                constituenciesLost: party.constituenciesLost || 'N/A',
                votePercentage: party.votePercentage || 'N/A'
            }));

            // Prepare party table data
            const partiesTable = [];
            partyData.forEach(party => {
                partiesTable.push([party.partyName, party.totalVotesSecured, party.constituenciesWon, party.constituenciesLost, party.votePercentage]);
            });

            // Add Parties Table to PDF
            doc.autoTable({
                startY: currentY + 10, // Adjusted start position for the table
                margin: { left: leftMargin, right: leftMargin }, // Set left and right margins
                head: [['Party Name', 'Total Votes Secured', 'Constituencies Won', 'Constituencies Lost', 'Vote Percentage']],
                body: partiesTable,
                theme: 'striped',
                styles: { overflow: 'linebreak' },
                columnStyles: {
                    0: { cellWidth: 'auto' },
                    1: { cellWidth: 'auto' },
                    2: { cellWidth: 'auto' },
                    3: { cellWidth: 'auto' },
                    4: { cellWidth: 'auto' }
                }
            });
        } else {
            doc.setFont("helvetica", "bold");
            doc.text("No Party Information Available", leftMargin, currentY + 10);
        }

        // Save the PDF
        doc.save("national_report_data.pdf");
    };

    const handleExportNationalReportPDFPresidential = () => {
        const doc = new jsPDF();
        const leftMargin = 20; // Left margin
        const topMargin = 20;  // Top margin
        const lineSpacing = 8; // Space between lines

        // Get current date
        const currentDate = new Date().toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        // Set PDF title
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text(`National Report Data`, leftMargin, topMargin);
        doc.setFontSize(10);
        doc.text(`Candidate Type: ${type || 'N/A'}`, leftMargin, topMargin + 6);
        doc.setFontSize(10);

        // Add current date to the top-right corner
        doc.setFontSize(10);
        doc.setTextColor(100);
        doc.text(currentDate, doc.internal.pageSize.width - leftMargin, topMargin, null, null, 'right');

        // Polling Station Information
        doc.setFont("helvetica", "normal");
        let currentY = topMargin + lineSpacing * 2; // Start position for the first line after title and candidate type
        doc.text(`Total Polling Stations: ${reportData.counts.totalPollingStations}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Stations With No Results: ${reportData.counts.stationsWithNoResults}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Total Votes Cast: ${reportData.data.totalVotesCast}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Total Wrong Votes: ${reportData.data.totalWrongVotes}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Total Voters: ${reportData.data.totalVoters}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Total Constituencies: ${reportData.data.totalConstituencies}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Regions Count: ${reportData.regionCount}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Districts Count: ${reportData.districtCount}`, leftMargin, currentY);
        currentY += lineSpacing;
        doc.text(`Constituency Count: ${reportData.constituencyCount}`, leftMargin, currentY);
        currentY += lineSpacing;

        // Candidates and Vote Secured Table
        if (Array.isArray(reportData.data.candidates) && reportData.data.candidates.length > 0) {
            const candidateData = reportData.data.candidates.map(candidate => ({
                candidateName: candidate.candidateName || '0',
                partyName: candidate.partyName || '0',
                totalVotesSecured: candidate.totalVotesSecured || '0',
                constituenciesWon: candidate.constituenciesWon || '0',
                votePercentage: candidate.votePercentage || '0'
            }));

            // Prepare candidate table data
            const candidatesTable = [];
            candidateData.forEach(candidate => {
                candidatesTable.push([candidate.candidateName, candidate.partyName, candidate.totalVotesSecured, candidate.constituenciesWon, candidate.votePercentage]);
            });

            // Add Candidates Table to PDF
            doc.autoTable({
                startY: currentY + 10, // Adjusted start position for the table
                margin: { left: leftMargin, right: leftMargin }, // Set left and right margins
                head: [['Candidate Name', 'Party Name', 'Total Votes Secured', 'Constituencies Won', 'Vote Percentage']],
                body: candidatesTable,
                theme: 'striped',
                styles: { overflow: 'linebreak' },
                columnStyles: {
                    0: { cellWidth: 'auto' },
                    1: { cellWidth: 'auto' },
                    2: { cellWidth: 'auto' },
                    3: { cellWidth: 'auto' },
                    4: { cellWidth: 'auto' }
                }
            });
        } else {
            doc.setFont("helvetica", "bold");
            doc.text("No Candidate Information Available", leftMargin, currentY + 10);
        }

        // Save the PDF
        doc.save("national_report_data.pdf");
    };

    return (
        <div className="page-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Reports & Analytics</h4>
                                <h6>Generate and View Reports</h6>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginBottom: "5px", display: "flex", justifyContent: "end" }}>
                        {
                            (type == "Presidential") && <button className="btn btn-success" onClick={handleExportNationalReportPDFPresidential}>
                                Export to PDF
                            </button>
                        }
                        {
                            (type == "Parliamentary") && <button className="btn btn-success" onClick={handleExportNationalReportPDF}>
                                Export to PDF
                            </button>
                        }
                    </div>

                    {/* Form for selecting region, district, etc */}
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Candidate Type</label>
                                        <select
                                            name="candidateType"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                            className="form-control"
                                        >
                                            <option value="">Select Candidate Type</option>
                                            <option value="Parliamentary">Parliamentary</option>
                                            <option value="Presidential">Presidential</option>
                                        </select>
                                    </div>
                                </div>
                               
                                <div className="col-md-4">
                                    <button onClick={submit} className="btn btn-primary mt-4">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Show report details */}
                    {reportData && (
                        <>
                            <div className="row mb-4">
                                {/* Region Count */}
                                <div className="col-md-3">
                                    <div className="card text-white bg-info mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Voters</h5>
                                            <p className="card-text">{reportData?.totalVoters || 0}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="card text-white bg-success mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">No. of Constituencies</h5>
                                            <p className="card-text">{reportData?.constituencyCount || 0}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-3">
                                    <div className="card text-white bg-secondary mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Votes Cast</h5>
                                            <p className="card-text">{parseInt(reportData?.data?.totalVotesCast) + parseInt(reportData?.data?.totalWrongVotes)}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Total Rejected Votes */}

                                <div className="col-md-3">
                                    <div className="card text-white bg-warning mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Rejected Votes</h5>
                                            <p className="card-text">{reportData?.data?.totalWrongVotes || 0}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-info mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Polling Stations</h5>
                                            <p className="card-text">{reportData?.counts?.totalPollingStations}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-success mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S with results</h5>
                                            <p className="card-text">{Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-secondary mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S with no results</h5>
                                            <p className="card-text">{reportData?.counts?.stationsWithNoResults ?? 0}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-white bg-warning mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title">P.S %</h5>
                                            <p className="card-text"> {(
                                                ((Number(reportData?.counts?.totalPollingStations ?? 0) - Number(reportData?.counts?.stationsWithNoResults ?? 0)) /
                                                    Number(reportData?.counts?.totalPollingStations ?? 1)) * 100
                                            ).toFixed(2)}%</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* Table for displaying candidate or party data */}
                            <div className="card mb-4 shadow">
                                <div className="card-body">
                                    <h5 className="card-title">{type === "Presidential" ? "Candidates Data" : "Parties Data"}</h5>
                                    <table className="table table-striped table-bordered">
                                        <thead className="thead-dark">
                                            <tr>
                                                {type === "Presidential" ? (
                                                    <>
                                                        <th>Candidate Name</th>
                                                        <th>Party Name</th>
                                                        <th>Total Votes Secured</th>
                                                        <th>Constituencies Won</th>
                                                        <th>Vote Percentage</th>
                                                    </>
                                                ) : (
                                                    <>
                                                        <th>Party Name</th>
                                                        <th>Total Votes Secured</th>
                                                        <th>Constituencies Won</th>
                                                        <th>Constituencies Lost</th>
                                                        <th>Vote Percentage</th>
                                                    </>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {type === "Presidential" ? (
                                                isArrayAndNotEmpty(reportData?.data?.candidates) ? (
                                                    reportData.data.candidates.map((candidate) => (
                                                        <tr key={candidate?.candidateName}>
                                                            <td>{candidate?.candidateName || "N/A"}</td>
                                                            <td>{candidate?.partyName || "N/A"}</td>
                                                            <td>{candidate?.totalVotesSecured || 0}</td>
                                                            <td>{candidate?.constituenciesWon || 0}</td>
                                                            <td>{candidate?.votePercentage || "0.00%"}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5">No data available</td>
                                                    </tr>
                                                )
                                            ) : (
                                                // Check the correct path for partyData
                                                isArrayAndNotEmpty(reportData?.data?.partyData) ? (
                                                    reportData.data.partyData?.map((party) => (
                                                        <tr key={party?.partyName}>
                                                            <td>{party?.partyName || "N/A"}</td>
                                                            <td>{party?.totalVotesSecured || 0}</td>
                                                            <td>{party?.constituenciesWon || 0}</td>
                                                            <td>{party?.constituenciesLost || 0}</td>
                                                            <td>{party?.votePercentage || "0.00%"}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5">No data available</td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                            <div className="card mb-4 shadow my-3">
                                <div className="card-body">
                                    <h5 className="card-title">Regions Information</h5>
                                    <Table
                                        columns={columns}
                                        dataSource={list}
                                        pagination={{ pageSize: 5 }}

                                    />

                                </div>
                            </div>
                            <div className="card mb-4 shadow">
                                <div className="card-body">
                                    <h5 className="card-title">Top Parties : {type == "Presidential" ? "Votes(%)" : "No. of Seats"}</h5>
                                    <Table
                                        columns={pastRecordsColumns}
                                        dataSource={pastRecords}
                                        pagination={{ pageSize: 5 }}
                                    />
                                </div>
                            </div>
                            <div className="card mb-4 shadow my-3">
                                <div className="card-body">
                                    <h5 className="card-title">Past Results</h5>
                                    <Table
                                        columns={pastColumns}
                                        dataSource={pastResult}
                                        pagination={{ pageSize: 5 }}

                                    />

                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default AdminNationalReport;
