import React, { useEffect, useState } from "react";
import { Search, Edit, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../Utils/urls";

const UserManagement = () => {
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // State for filtered data
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [id, setId] = useState("");
  const [partyId, setPartyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const [captchaToken, setCaptchaToken] = useState(null);
    const handleCaptcha = (value) => {
        setCaptchaToken(value);
    };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setName("");
    setEmail("");
    setPassword("");
    setCountryCode("");
    setPhone("");
    setId("");
    setEdit(false);
  };

  const { apiData, reFetch } = useFetch("getAllUsers");
  const getAllParties = useFetch("getAllParties");

  useEffect(() => {
    if (apiData?.data?.controllers) {
      const transformedData = apiData?.data?.controllers.map((region) => ({
        id: region.id,
        name: region.name,
        email: region.email,
        party: region?.party?.name,
        countryCode: region.countryCode,
        phoneNum: region.phone,
        phone: region.phone,
        role: "Admin",
      }));
      setUserData(transformedData);
      setFilteredData(transformedData); // Set initial filtered data
    }
  }, [apiData]);

  // Handle search query change and filter data
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredRecords = userData.filter(
      (user) =>
        user.name.toLowerCase().match(query) ||
        user.email.toLowerCase().match(query) ||
        user.phone.toString().match(query)
    );
    setFilteredData(filteredRecords);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
    },
    {
      title: "Party",
      dataIndex: "party",
      sorter: (a, b) => a.party.length - b.party.length,
      render: (status) => (
        <span className="btn btn-info btn-sm" style={{ width: "120px" }}>
          {status}
        </span>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => a.role.length - b.role.length,
      render: (status) => (
        <span className="btn btn-success btn-sm" style={{ width: "120px" }}>
          {status}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link className="me-2 p-2">
              <Edit onClick={() => editUser(record)} className="feather-edit" />
            </Link>
            <Link className="confirm-text p-2" to="#">
              <Trash2 onClick={() => deleteUser(record.id)} className="feather-trash-2" />
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const addController = async () => {
    if (!captchaToken) {
      error_toaster("Please complete the reCAPTCHA.");
      return;
  }
    setLoading(true);
    let response = await PostApi("addUser", {
      name: name,
      email: email,
      captcha: captchaToken,
      partyId: partyId,
      phone: phone,
      password: password,
    });
    if (response.data.status === "1") {
      setLoading(false);
      reFetch();
      setShow(false);
      success_toaster(response.data.message);
    } else {
      setLoading(false);
      error_toaster(response.data.message);
    }
  };

  const deleteUser = async (id) => {
    let response = await PostApi("deleteUser", {
      id: id,
    });
    if (response.data.status === "1") {
      success_toaster(response.data.message);
      reFetch();
    } else {
      error_toaster(response.data.message);
    }
  };

  const editUser = (obj) => {
    setEdit(true);
    setName(obj.name);
    setEmail(obj.email);
    setPhone(obj.phoneNum);
    setCountryCode(obj.countryCode);
    setPassword(obj.password);
    setId(obj.id);
    setShow(true);
  };

  const updateUser = async () => {
    setLoading(true);
    let response = await PostApi("udpateUser", {
      id: id,
      name: name,
      email: email,
      partyId: partyId,
      countryCode: countryCode,
      phone: phone,
      password: password,
    });
    if (response.data.status === "1") {
      reFetch();
      setLoading(false);
      setShow(false);
      success_toaster(response.data.message);
    } else {
      setLoading(false);
      error_toaster(response.data.message);
    }
  };

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Admin Management</h4>
                <h6>Manage Admin</h6>
              </div>
            </div>
            <div className="page-btn">
              <button onClick={handleShow} className="btn btn-added">
                <PlusCircle className="me-2" />
                Add New
              </button>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search by Name, Email, or Phone"
                      className="form-control form-control-sm formsearch"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <Link to="#" className="btn btn-searchset">
                      <Search className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>

              <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: 5 }} />
            </div>
          </div>

          {/* Add Admin Modal */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{edit ? "Update Admin" : "Add Admin"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="p-2">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                  />
                </div>
                <div className="form-group">
                  <label>Phone No.</label>
                  <input
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Phone Number"
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="*******"
                  />
                </div>
                <div className="form-group">
                  <label>Party</label>
                  <select onChange={(e) => setPartyId(e.target.value)} className="form-control">
                    <option>Select Party</option>
                    {getAllParties?.apiData?.data?.parties?.map((party) => (
                      <option value={party.id} key={party.id}>
                        {party.name}
                      </option>
                    ))}
                  </select>
                </div>
               <div style={{padding:"10px 0px"}}>
               <ReCAPTCHA
                                    sitekey={SITE_KEY} // Replace with your reCAPTCHA site key
                                    onChange={handleCaptcha}
                                />
               </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button type="button" className="btn btn-secondary" onClick={handleClose}>
                    Close
                  </button>
                  {edit ? (
                    <button onClick={updateUser} type="submit" className="btn btn-primary">
                      Update
                    </button>
                  ) : (
                    <button onClick={addController} type="submit" className="btn btn-primary">
                      Save
                    </button>
                  )}
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
