import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import useFetch from "../../ApiClient/GetApi";
import Loader from "../../Utils/Loader";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster } from "../../Utils/Toaster";
import Table from "../../core/pagination/datatable";
import Chart from "react-apexcharts";
import formatDate from "../../Utils/DateFormat";
import jsPDF from "jspdf";
import "jspdf-autotable";

ChartJS.register(
  ArcElement,
  ChartTooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const DistrictReport = () => {
  const [districtId, setDistrictId] = useState(null);
  const [partyId, setPartyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [list, setList] = useState([]);
  const [chartList, setChartList] = useState([]);
  const [pastResult, setPastResult] = useState([]);
  const [pastRecords, setPastRecords] = useState([]);
  const [headers, setHeaders] = useState([]);
  const { apiData } = useFetch("getAllDistricts");
 
  useEffect(()=>{
    setPartyId(localStorage.getItem("partyId"))
  },[partyId])
  const districtChange = async (id) => {
    setDistrictId(id);
  };

  const submit = async () => {
    if (!type || !partyId || !districtId) {
      error_toaster("All Fields are required!");
      return false;
    }
    setLoading(true);
    let response = await PostApi("districtReport", {
      partyId,
      districtId: districtId,
      type,
    });
    // console.log(response);
    if (response.data && response.data.status === "1") {
      setReportData(response?.data?.data);
      setChartList(response?.data?.data?.chartData);
      // setList(response?.data?.data?.data?.constituencies); // Store constituencies data for chart
      setLoading(false);
      // console.log(response.data.data);
      setHeaders(response?.data?.data?.pastRecords?.headers || []);
      setPastRecords(response?.data?.data?.pastRecords?.rows || []);
    } else {
      error_toaster("No Result found!");
      setLoading(false);
    }
  };
  const [chartOptions, setChartOptions] = useState({
    series: [
      { name: "Parliamentary", data: [] },
      { name: "Presidential", data: [] },
    ],
    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 320,
      stacked: true,
      zoom: { enabled: true },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: "20%",
      },
    },
    dataLabels: { enabled: false },
    yaxis: { min: 0, max: 300, tickAmount: 5 },
    xaxis: { categories: [] },
    legend: { show: false },
    fill: { opacity: 1 },
  });

  const pastRecordsColumns = headers.map((header, index) => ({
    title: header,
    dataIndex: header.toLowerCase(),
    key: index,
  }));
  useEffect(() => {
    if (chartList && chartList.length > 0) {
      const categories = []; // To store party names
      const seriesData = []; // To store votes secured for each party

      let maxVote = 300;

      // Iterate through the chartList to extract party names and their votes secured
      chartList.forEach((party) => {
        categories.push(party.partyName);
        const votesSecured = parseInt(party.totalVotesSecured, 10) || 0;
        seriesData.push(votesSecured);
        maxVote = Math.max(maxVote, votesSecured);
      });

      // Create a single series array with the party names and vote counts
      const seriesArray = [
        {
          name: "Total Votes Secured", // This is the label for the series in the chart
          data: seriesData, // Set the data as the votes secured
        },
      ];

      // Update the chart options with the new series and categories
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: seriesArray, // Use the constructed series array
        xaxis: { categories: categories.length > 0 ? categories : [""] }, // Set the categories as the party names
        yaxis: { ...prevOptions.yaxis, max: maxVote + 50 }, // Adjust the y-axis max value
      }));
    }
  }, [chartList]);
  // console.log(chartList);

  useEffect(() => {
    if (reportData) {
      const formattedData = reportData?.constituencies?.map((party) => ({
        name: party.name,
        totalVoters: party.totalVoters,
        totalPollingStations: party.totalPollingStations,
        totalVotesCast: party.totalVotesSecured ? party.totalVotesSecured : 0,
      }));
      setList(formattedData);
      const record = reportData?.pastResults?.map((party) => ({
        year: party.year,
        votes: party.votes,
        candidate: party.candidate,
        candidatetype: party.candidatetype?.name ?? "No Type",
        percentage: `${party.percentage}%` ?? "0",
        district: party.constituency?.district?.name,
        region: party?.region?.name,
        party: party?.party?.name,
        createdAt: formatDate(party.createdAt),
      }));

      setPastResult(record);
    }
  }, [reportData]);
  const pastColumns = [
    {
      title: "Year",
      dataIndex: "year",
      sorter: (a, b) => a.PartyName.length - b.PartyName.length,
    },
    {
      title: "Candidate",
      dataIndex: "candidate",
      sorter: (a, b) => a.PartyName.length - b.PartyName.length,
      render: (status) => (
        <span className="btn btn-warning btn-sm" style={{ width: "120px" }}>
          {status}
        </span>
      ),
    },
    {
      title: "Candidate Type",
      dataIndex: "candidatetype",
    },
    {
      title: "Party",
      dataIndex: "party",
      sorter: (a, b) => a.PartyName.length - b.PartyName.length,
      render: (status) => (
        <span className="btn btn-info btn-sm" style={{ width: "120px" }}>
          {status}
        </span>
      ),
    },
    {
      title: "No. of Votes",
      dataIndex: "votes",
    },
    {
      title: "% of Votes",
      dataIndex: "percentage",
    },
    {
      title: "Region",
      dataIndex: "region",
      sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.PartyName.length - b.PartyName.length,
    },
    {
      title: "No. of Polling Stations",
      dataIndex: "totalPollingStations",
      sorter: (a, b) => a.PartyName.length - b.PartyName.length,
    },
    {
      title: "Total Voters",
      dataIndex: "totalVoters",
    },
    {
      title: "Vote's Cast",
      dataIndex: "totalVotesCast",
    },
  ];
// console.log(JSON.stringify(reportData))
  // Get unique party names from the reportData
  const uniqueParties = reportData?.data?.constituencies?.reduce(
    (acc, cons) => {
      cons.parties.forEach((party) => {
        if (!acc.includes(party.partyName)) {
          acc.push(party.partyName);
        }
      });
      return acc;
    },
    []
  );

  console.log(JSON.stringify(reportData));

  const handleExportPDF = () => {
    const doc = new jsPDF();
  
    // Get current date
    const currentDate = new Date().toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  
    // Set PDF title
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text(
      `District: ${reportData.districtData.name || ""}`,
      14,
      10
    );
    doc.setFontSize(10);
    doc.text(`Candidate Type: Parliamentary`, 14, 18); // Assuming Parliamentary as the Candidate Type
  
    // Add current date to the corner
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(currentDate, doc.internal.pageSize.width - 30, 10, null, null, "right");
  
    // Total Votes Cast and Wrong Votes
    if (reportData.data) {
      doc.setFont("helvetica", "bold");
      doc.text(`Total Votes Cast: ${reportData.data.totalVotesCast || 0}`, 14, 24);
      doc.text(`Total Rejected Votes: ${reportData.data.totalWrongVotes || 0}`, 14, 30);
    }
  
    // Display Total Voters, Total Polling Stations, and Constituencies Count
    const totalVoters = reportData.totalVoters || 'N/A';
    const totalConstituencies = reportData.constLength || 'N/A';
    const totalPollingStations = reportData.counts.totalPollingStations || 1; // Avoid division by zero
    const stationsWithNoResults = reportData.counts.stationsWithNoResults || 0;
    const stationsWithResults = totalPollingStations - stationsWithNoResults;
  
    // Calculate polling station result percentages
    const resultAnnouncedPercentage = ((stationsWithResults / totalPollingStations) * 100).toFixed(2);
    const noResultPercentage = ((stationsWithNoResults / totalPollingStations) * 100).toFixed(2);
  
    doc.text(`Total Voters: ${totalVoters}`, 14, 36);
    doc.text(`Total Constituencies: ${totalConstituencies}`, 14, 42);
    doc.text(`Total Polling Stations: ${totalPollingStations}`, 14, 48);
    doc.text(`Polling Stations with Results: ${stationsWithResults} (${resultAnnouncedPercentage}%)`, 14, 54);
    doc.text(`Polling Stations without Results: ${stationsWithNoResults} (${noResultPercentage}%)`, 14, 60);
  
    // District Information
    if (reportData.districtData) {
      const districtTableData = [
        { label: "District", value: reportData.districtData.name },
      ];
  
      doc.setFont("helvetica", "bold");
      doc.text("District Information:", 14, 70);
      doc.autoTable({
        startY: 75,
        head: [["Label", "Value"]],
        body: districtTableData.map(({ label, value }) => [label, value]),
      });
  
      // Constituencies Information
      const constituenciesTableData = reportData.constituencies.map(
        (constituency) => [
          constituency.name,
          constituency.totalVoters,
          constituency.totalPollingStations,
        ]
      );
  
      doc.setFont("helvetica", "bold");
      doc.text(
        "Constituencies Information:",
        14,
        doc.autoTable.previous.finalY + 10
      );
      doc.autoTable({
        head: [["Constituency Name", "Total Voters", "Total Polling Stations"]],
        body: constituenciesTableData,
        startY: doc.autoTable.previous.finalY + 20,
      });
  
      // Party Votes by Constituency
      reportData.data.constituencies.forEach((constituency, index) => {
        const startY = doc.autoTable.previous.finalY + 15 + index * 10; // Adjust startY for each constituency section
  
        doc.setFont("helvetica", "bold");
        doc.text(`Party Votes in ${constituency.constituencyName}:`, 14, startY);
  
        const partyVotesData = constituency.parties.map((party) => [
          party.partyName,
          party.totalVotesSecured,
          party.votePercentage,
        ]);
  
        doc.autoTable({
          head: [["Party Name", "Votes Secured", "Vote Percentage"]],
          body: partyVotesData,
          startY: startY + 5,
          theme: "striped",
        });
      });
    } else {
      doc.setFont("helvetica", "bold");
      doc.text("District Information Not Available", 14, 70);
    }
  
    // Save the PDF
    doc.save("district_report.pdf");
  };
  

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Reports & Analytics</h4>
                <h6>Generate and View Reports</h6>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              className="btn btn-success"
              style={{ marginBottom: "5px" }}
              onClick={handleExportPDF}
            >
              Export to PDF
            </button>
          </div>

          {/* Form for selecting region, district, etc */}
          <div className="card mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Candidate Type</label>
                    <select
                      name="candidateType"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      className="form-control"
                    >
                      <option value="">Select Candidate Type</option>
                      <option value="Parliamentary">Parliamentary</option>
                      <option value="Presidential">Presidential</option>
                    </select>
                  </div>
                </div>
                
                <div className="col-md-4">
                  <div className="form-group">
                    <label>District</label>
                    <select
                      name="district"
                      value={districtId}
                      onChange={(e) => districtChange(e.target.value)}
                      className="form-control"
                    >
                      <option value="">Select District</option>
                      {apiData?.data?.list?.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <button onClick={submit} className="btn btn-primary mt-3">
                Submit
              </button>
            </div>
          </div>

          {/* Show report details */}
          {reportData && (
            <>
              <div className="row mb-4">
                <div className="col-md-3">
                  <div className="card text-white bg-warning mb-3">
                    <div className="card-body">
                      <h5 className="card-title">Total Voters</h5>
                      <p className="card-text">{reportData?.totalVoters}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card text-white bg-info mb-3">
                    <div className="card-body">
                      <h5 className="card-title">No. of Constituencies</h5>
                      <p className="card-text">{reportData?.constLength}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card text-white bg-success mb-3">
                    <div className="card-body">
                      <h5 className="card-title">Total Votes Cast</h5>
                      <p className="card-text">
                        {parseInt(reportData?.data?.totalVotesCast) +
                          parseInt(reportData?.data?.totalWrongVotes)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card text-white bg-secondary mb-3">
                    <div className="card-body">
                      <h5 className="card-title">Total Rejected Votes</h5>
                      <p className="card-text">
                        {reportData?.data?.totalWrongVotes}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card text-white bg-secondary mb-3">
                    <div className="card-body">
                      <h5 className="card-title">Total Polling Stations</h5>
                      <p className="card-text">
                        {reportData?.counts?.totalPollingStations}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card text-white bg-info mb-3">
                    <div className="card-body">
                      <h5 className="card-title">P.S with results</h5>
                      <p className="card-text">
                        {Number(reportData?.counts?.totalPollingStations ?? 0) -
                          Number(
                            reportData?.counts?.stationsWithNoResults ?? 0
                          )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card text-white bg-success mb-3">
                    <div className="card-body">
                      <h5 className="card-title">P.S with no results</h5>
                      <p className="card-text">
                        {reportData?.counts?.stationsWithNoResults ?? 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card text-white bg-secondary mb-3">
                    <div className="card-body">
                      <h5 className="card-title">P.S %</h5>
                      <p className="card-text">
                        {" "}
                        {(
                          ((Number(
                            reportData?.counts?.totalPollingStations ?? 0
                          ) -
                            Number(
                              reportData?.counts?.stationsWithNoResults ?? 0
                            )) /
                            Number(
                              reportData?.counts?.totalPollingStations ?? 1
                            )) *
                          100
                        ).toFixed(2)}
                        %
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Table for displaying constituencies and party votes */}
              <div className="card mb-4 shadow">
                <div className="card-body">
                  <h5 className="card-title">
                    Constituency and Party Votes ({type})
                  </h5>
                  <div className="table-responsive" style={{ overflowX: "auto" }}>
    <table className="table table-striped table-bordered">
        <thead className="thead-dark">
            <tr>
                <th>Constituency Name</th>
                {uniqueParties?.map((partyName) => (
                    <th key={partyName}>{partyName}</th>
                ))}
            </tr>
        </thead>
        <tbody>
            {reportData?.data?.constituencies?.map((constituency) => (
                <tr key={constituency.constituencyName}>
                    <td>{constituency.constituencyName}</td>
                    {uniqueParties?.map((partyName) => {
                        const party = constituency.parties.find(
                            (p) => p.partyName === partyName
                        );
                        return (
                            <td key={partyName}>
                                {party ? party.totalVotesSecured : 0} - (
                                {party ? party.votePercentage : 0})
                            </td>
                        );
                    })}
                </tr>
            ))}
        </tbody>
    </table>
</div>

                </div>
              </div>
              <div className="card-body bg-white shadow my-3">
                {chartList?.length > 0 && (
                  <Chart
                    options={chartOptions}
                    series={chartOptions.series}
                    type="bar"
                    height={320}
                  />
                )}
              </div>

              <div className="card mb-4 shadow my-3">
                <div className="card-body">
                  <h5 className="card-title">Constituency Information</h5>
                  <Table
                    columns={columns}
                    dataSource={list}
                    pagination={{ pageSize: 5 }}
                  />
                </div>
              </div>
              <div className="card mb-4 shadow">
                <div className="card-body">
                  <h5 className="card-title">
                    Top Parties :{" "}
                    {type == "Presidential" ? "Votes(%)" : "No. of Seats"}
                  </h5>
                  <Table
                    columns={pastRecordsColumns}
                    dataSource={pastRecords}
                    pagination={{ pageSize: 5 }}
                  />
                </div>
              </div>
              <div className="card mb-4 shadow my-3">
                <div className="card-body">
                  <h5 className="card-title">Past Results</h5>
                  <Table
                    columns={pastColumns}
                    dataSource={pastResult}
                    pagination={{ pageSize: 5 }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DistrictReport;

// import React, { useEffect, useState } from "react";
// import "react-datepicker/dist/react-datepicker.css";
// import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
// import useFetch from "../../ApiClient/GetApi";
// import Loader from "../../Utils/Loader";
// import { PostApi } from "../../ApiClient/PostApi";
// import { error_toaster } from "../../Utils/Toaster";
// import Chart from "react-apexcharts";
// import { IMAGE_URL } from "../../Utils/urls";
// import Table from "../../core/pagination/datatable";

// ChartJS.register(ArcElement, ChartTooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

// const DistrictReport = () => {

//     const [districtId, setDistrictId] = useState(null);

//     const [partyId, setPartyId] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [type, setType] = useState(null);
//     const [reportData, setReportData] = useState(null);
//     const [list, setList] = useState([]);

//     const { apiData } = useFetch("getAllDistricts");
//     const getAllParties = useFetch("getAllParties");

//     const districtChange = async (id) => {
//         setDistrictId(id);

//     };

//     const submit = async () => {
//         if(!type || !partyId || !districtId){
//             error_toaster("All Feids are required!");
//             return false;
//         }
//         setLoading(true);
//         let response = await PostApi("districtReport", { partyId, districtId, type });
//         if(response.data.data.candidates?.candidates?.length > 0){
//             setReportData(response.data?.data);
//             setList(response?.data?.data?.chartData);
//             setLoading(false);
//         }
//         else{
//             error_toaster("No Result found!");
//             setLoading(false);
//             return false;
//         }
//         setLoading(false);
//     };

//     const calculatePercentage = (securedVotes, totalVotes) => {
//         return ((securedVotes / totalVotes) * 100).toFixed(2);
//     };
//     const [chartOptions, setChartOptions] = useState({
//         series: [
//             { name: "Parliamentary", data: [] },
//             { name: "Presidential", data: [] },
//         ],
//         colors: ["#28C76F", "#EA5455"],
//         chart: {
//             type: "bar",
//             height: 320,
//             stacked: true,
//             zoom: { enabled: true },
//         },
//         responsive: [
//             {
//                 breakpoint: 280,
//                 options: {
//                     legend: {
//                         position: "bottom",
//                         offsetY: 0,
//                     },
//                 },
//             },
//         ],
//         plotOptions: {
//             bar: {
//                 horizontal: false,
//                 borderRadius: 4,
//                 borderRadiusApplication: "end",
//                 borderRadiusWhenStacked: "all",
//                 columnWidth: "20%",
//             },
//         },
//         dataLabels: { enabled: false },
//         yaxis: { min: 0, max: 300, tickAmount: 5 },
//         xaxis: { categories: [] },
//         legend: { show: false },
//         fill: { opacity: 1 },
//     });

//     useEffect(() => {
//         if (list && list.length > 0) {
//             const categories = [];
//             const parliamentaryVotes = [];
//             const presidentialVotes = [];
//             let maxVote = 300;

//             list.forEach((party) => {
//                 categories.push(party.name);

//                 const presidentialVote =
//                     party.data.find((vote) => vote.name === "Presidential")?.y || 0;
//                 const parliamentaryVote =
//                     party.data.find((vote) => vote.name === "Parliamentary")?.y || 0;

//                 presidentialVotes.push(presidentialVote);
//                 parliamentaryVotes.push(parliamentaryVote);

//                 maxVote = Math.max(maxVote, presidentialVote, parliamentaryVote);
//             });

//             setChartOptions((prevOptions) => ({
//                 ...prevOptions,
//                 series: [
//                     { name: "Parliamentary", data: parliamentaryVotes },
//                     { name: "Presidential", data: presidentialVotes },
//                 ],
//                 xaxis: { categories: categories.length > 0 ? categories : [""] },
//                 yaxis: { ...prevOptions.yaxis, max: maxVote + 50 },
//             }));
//         }
//     }, [list]);

//     const columns = [
//         {
//             title: "Constituency Name",
//             dataIndex: "name",
//             sorter: (a, b) => a.name.length - b.name.length,
//         },
//         {
//             title: "Area",
//             dataIndex: "areaName",
//             sorter: (a, b) => a.party.length - b.party.length,
//             render: (party) => <span className="btn btn-warning btn-sm" style={{ width: "90px" }}>{party}</span>,
//         },
//         {
//             title: "Area Code",
//             dataIndex: "areaCode",
//             render: (type) => <span className="btn btn-success btn-sm" style={{ width: "90px" }}>{type}</span>,
//         },
//         {
//             title: "Total Polling Stations",
//             dataIndex: "totalPollingStations",
//         },
//         {
//             title: "Total Votes",
//             dataIndex: "totalVoters",
//         },

//     ];

//     return (
//         <div className="page-wrapper">
//             {loading ? <Loader /> : <div className="content">
//                 <div className="page-header">
//                     <div className="add-item d-flex">
//                         <div className="page-title">
//                             <h4>Reports & Analytics</h4>
//                             <h6>Generate and View Reports</h6>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Form for selecting region, district, etc */}
//                 <div className="card mb-4">
//                     <div className="card-body">
//                         <div className="row">
//                         <div className="col-md-4">
//                                 <div className="form-group">
//                                     <label>Candidate Type</label>
//                                     <select name="candidateType" value={type} onChange={(e) => setType(e.target.value)} className="form-control">
//                                         <option value="">Select Candidate Type</option>
//                                         <option value="Parliamentary">Parliamentary</option>
//                                         <option value="Presidential">Presidential</option>
//                                     </select>
//                                 </div>
//                             </div>
//                             <div className="col-md-4">
//                                 <div className="form-group">
//                                     <label>Select Party</label>
//                                     <select name="party" value={partyId} onChange={(e) => setPartyId(e.target.value)} className="form-control">
//                                         <option value="">Select Party</option>
//                                         {
//                                             getAllParties?.apiData?.data?.parties?.map((party) => (
//                                                 <option key={party.id} value={party.id} >{party.name}</option>
//                                             ))
//                                         }
//                                     </select>
//                                 </div>
//                             </div>

//                             <div className="col-md-4">
//                                 <div className="form-group">
//                                     <label>District</label>
//                                     <select name="district" value={districtId} onChange={(e) => districtChange(e.target.value)} className="form-control">
//                                         <option value="">Select District</option>
//                                         {apiData?.data?.list?.map((district) => (
//                                             <option key={district.id} value={district.id}>{district.name}</option>
//                                         ))}
//                                     </select>
//                                 </div>
//                             </div>

//                         </div>
//                         <button onClick={submit} className="btn btn-primary mt-3">Submit</button>
//                     </div>
//                 </div>

//                 {/* Show report details */}
//                 {reportData && (
//                     <>
//                         <div className="row mb-4">
//                         <div className="col-md-4">
//                                 <div className="card text-white bg-warning mb-3">
//                                     <div className="card-body">
//                                         <h5 className="card-title">District</h5>
//                                         <p className="card-text">{reportData.districtData?.name}</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-4">
//                                 <div className="card text-white bg-info mb-3">
//                                     <div className="card-body">
//                                         <h5 className="card-title">Region</h5>
//                                         <p className="card-text">{reportData.districtData?.region?.name}</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-4">
//                                 <div className="card text-white bg-danger mb-3">
//                                     <div className="card-body">
//                                         <h5 className="card-title">Total e-Ballots Cast</h5>
//                                         <p className="card-text">{reportData.candidates.totalVoteCasts}</p>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="col-md-4">
//                                 <div className="card text-white bg-success mb-3">
//                                     <div className="card-body">
//                                         <h5 className="card-title">Total Rejected e-Ballots</h5>
//                                         <p className="card-text">{reportData.candidates.totalWrongVotes}</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-4">
//                                 <div className="card text-white bg-secondary mb-3">
//                                     <div className="card-body">
//                                         <h5 className="card-title">Total e-Ballots</h5>
//                                         <p className="card-text">{reportData.candidates.totalVoteCasts}</p>
//                                     </div>
//                                 </div>
//                             </div>

//                         </div>

//                         {/* Display candidate data */}
//                         <div className="card">
//                             <div className="card-body">
//                             <table className="table table-striped table-bordered">
//                             <thead className="thead-dark">
//                                         <tr>
//                                             <th>Candidate Name</th>
//                                             <th>Party</th>
//                                             <th>No. Votes Secured (In Figures)</th>
//                                             <th>% of Votes Secured</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {reportData.candidates?.candidates?.map((candidateData) => {
//                                             return (
//                                                 <tr key={candidateData.id}>
//                                                     <td >{candidateData.name}</td>
//                                                     <td><div><img style={{height:"40px",width:"40px",objectFit:"contain"}} src={IMAGE_URL+candidateData?.image} /></div></td>
//                                                     <td >{candidateData.totalVotesSecured}</td>
//                                                     <td>{calculatePercentage(candidateData.totalVotesSecured, reportData.candidates.totalVoteCasts)}%</td>
//                                                 </tr>
//                                             );
//                                         })}

//                                         <tr>
//                                             <td colSpan="2">Total Voters</td>
//                                             <td>
//                                                 { reportData.candidates.candidates.reduce((sum, candidate) => sum + candidate.totalVotesSecured, 0)}
//                                             </td>
//                                             <td></td>
//                                         </tr>
//                                         <tr>
//                                             <td colSpan="2">Total Rejected Votes</td>
//                                             <td>{reportData.candidates.totalWrongVotes}</td>
//                                             <td></td>
//                                         </tr>
//                                         <tr>
//                                             <td colSpan="2">Total Votes Cast</td>
//                                             <td>{parseInt(reportData.candidates.totalVoteCasts) + parseInt(reportData.candidates.totalWrongVotes)}</td>
//                                             <td></td>
//                                         </tr>
//                                     </tbody>

//                                 </table>
//                             </div>
//                         </div>

//                         {/* Display Winner */}
//                         <div className="card mt-4">
//                             <div className="card-body">
//                                 <h4>Winner</h4>
//                                 {reportData.candidates.winners.map((winner) => (
//                                     <div key={winner.id} className="alert alert-success">
//                                         {winner.name} from {winner.party} with {winner.totalVotesSecured} votes secured!
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>
//                     </>
//                 )}
//                  <div className="card-body bg-white shadow">
//                     {list?.length > 0 && (
//                         <Chart
//                             options={chartOptions}
//                             series={chartOptions.series}
//                             type="bar"
//                             height={320}
//                         />
//                     )}
//                 </div>
//                 {
//                     reportData && <div className="my-3 shadow">
//                     <Table columns={columns} dataSource={reportData?.constituencies} pagination={{ pageSize: reportData?.constituencies?.length }} />
//                     </div>
//                 }

//             </div>}
//         </div>
//     );
// };

// export default DistrictReport;
