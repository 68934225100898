import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { error_toaster } from "../../../Utils/Toaster";
import { PostApi } from "../../../ApiClient/PostApi";
import { Loader2 } from "../../../Utils/Loader";


// import PageLoader from "../../../core/loader";

const Signin = () => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const login = async () => {
    try {
      // Check if email and password are provided
      if (email === "") {
        error_toaster("Email is required");
        return false;
      }
      if (password === "") {
        error_toaster("Password is required");
        return false;
      }
  
      setLoading(true);
  
      // Make API request to login
      let response = await PostApi("login", { email, password });
      console.log(response?.data);
  
      // Check if login was successful
      if (response.data.status === "1") {
        const role = response?.data?.data?.role;
  
        // If the role is "user", extract and store permissions
        if (role === "user") {
          const permissions = response.data.data.permissions.map(
            (perm) => perm.authorization.name
          );
          localStorage.setItem("permissions", JSON.stringify(permissions));
        }
  
        // Store other necessary data in localStorage
        localStorage.setItem("partyId", response?.data?.data?.partyId);
        localStorage.setItem("loginstatus", "1");
        localStorage.setItem("role", role);
        localStorage.setItem("name", response?.data?.data?.name);
        localStorage.setItem("review", response?.data?.data?.checkReview);
        
        localStorage.setItem("accessToken", response?.data?.data?.accessToken);
        localStorage.setItem("sessionId", response?.data?.data?.sessionData?.id);
        if(response?.data?.data?.role == "member"){
          localStorage.setItem("userId", response?.data?.data?.controllerId);
        }
        else{
          localStorage.setItem("userId", response?.data?.data?.id);
        }
  
        setLoading(false);
  
        // Navigate based on the role
        if (role === "agent") {
          navigate("/agent-dashboard");
        } else if (role === "controller" || role == "member") {
          navigate("/controller-dashboard");
        } else if (role === "super admin") {
          navigate("/");
        } else if (role === "user") {
          navigate("/"); // Add a route for the user role if applicable
        } else if (role === "admin") {
          navigate("/admin-dashboard"); // Add a route for the admin role if applicable
        }
  
      } else {
        error_toaster(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      error_toaster(error.message);
    }
  };
  


  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };
  const route = all_routes;
  return (
    <div className="main-wrapper">
      {loading ? (
        <Loader2 />
      ) : (
        <div className="account-content">
          <div className="login-wrapper bg-img">
            <div className="login-content">
              <form>
                <div className="login-userset">
                  <div className="login-logo logo-normal">
                    <ImageWithBasePath src="assets/img/dddd.png" alt="img" />
                  </div>
                  <Link to={route.dashboard} className="login-logo logo-white">
                    <ImageWithBasePath src="assets/img/logo-white.png" alt />
                  </Link>
                  <div className="login-userheading">
                    <h3>Sign In</h3>
                    <h4>
                      Access the panel using your email and passcode.
                    </h4>
                  </div>
                  <div className="form-login mb-3">
                    <label className="form-label">Email Address</label>
                    <div className="form-addons">
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        className="form-control"
                      />
                      <ImageWithBasePath
                        src="assets/img/icons/mail.svg"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="form-login mb-3">
                    <label className="form-label">Password</label>
                    <div className="pass-group">
                      <input
                        onChange={(e) => setPassword(e.target.value)}
                        type={isPasswordVisible ? "text" : "password"}
                        className="pass-input form-control"
                      />
                      <span
                        className={`fas toggle-password ${isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                          }`}
                        onClick={togglePasswordVisibility}
                      ></span>
                    </div>
                  </div>
                  <div className="form-login authentication-check">
                    <div className="row">
                      <div className="col-12 d-flex align-items-center justify-content-between">
                        <div className="text-end">
                          <Link
                            className="forgot-link"
                            to={route.forgotPassword}
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-login">
                    <button
                      type="button"
                      onClick={login}
                      className="btn btn-login"
                    >
                      Sign In
                    </button>
                  </div>


                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signin;
