import React, { useState, useEffect, useMemo } from "react";
import { Edit } from "react-feather";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import { Trash2 } from "feather-icons-react/build/IconComponents";
import { Modal } from "react-bootstrap";
import { IMAGE_URL } from "../../Utils/urls";

const AddManagement = () => {
  const [addData, setAddData] = useState([]);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [id, setId] = useState("");
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setEdit(false);
    setShow(true);
    setId("");
    setImage(null);
    setUrl("");
  };

  const { apiData, reFetch } = useFetch("getAllAdds");

  const transformedData = useMemo(() => {
    if (apiData?.data) {
      return apiData.data.map((add) => ({
        key: add.id,
        image: add.image,
        url: add.url,
        status: add.status,
      }));
    }
    return [];
  }, [apiData]);

  useEffect(() => {
    setAddData(transformedData);
  }, [transformedData]);

  const editModal = (obj) => {
    setId(obj.key);
    setImage(null); // Set to null for file upload, as we won't prefill file inputs
    setUrl(obj.url);
    setShow(true);
    setEdit(true);
  };

  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };

  const addNewAd = async (e) => {
    e.preventDefault();
    if (!image || !url) {
      error_toaster("Image and URL are required!");
      return;
    }
    const formData = new FormData();
    formData.append("image", image);
    formData.append("url", url);

    setLoading(true);
    const response = await PostApi("addAd", formData, true); // `true` indicates multipart/form-data
    if (response.data.status === "1") {
      success_toaster(response.data.message);
      reFetch();
      setLoading(false);
      setShow(false);
    } else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  const updateAd = async () => {
    if (!url) {
      error_toaster("URL is required!");
      return;
    }
    const formData = new FormData();
    formData.append("id", id);
    if (image) formData.append("image", image); // Include image only if updated
    formData.append("url", url);

    setLoading(true);
    const response = await PostApi("updateAd", formData, true);
    if (response.data.status === "1") {
      success_toaster(response.data.message);
      reFetch();
      setLoading(false);
      setShow(false);
    } else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  const deleteAd = async (id) => {
    // if (!window.confirm("Are you sure you want to delete this ad?")) return;
    setLoading(true);
    const response = await PostApi("deleteAd", { id });
    if (response.data.status === "1") {
      success_toaster(response.data.message);
      reFetch();
      setLoading(false);
    } else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  const toggleStatus = async (id) => {
    setLoading(true);
    const response = await PostApi("toggleAdStatus", { id });
    if (response.data.status === "1") {
      success_toaster(response.data.message);
      reFetch();
      setLoading(false);
    } else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      render: (image) => <img src={IMAGE_URL+image} alt="Ad" style={{ width: "50px" }} />,
    },
    {
      title: "URL",
      dataIndex: "url",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status, record) => (
        <button
          className={`btn btn-sm ${status ? "btn-danger" : "btn-success"}`}
          onClick={() => toggleStatus(record.key)}
        >
          {status ? "Deactivate" : "Activate"}
        </button>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <div className="action-table-data">
          <Edit
            onClick={() => editModal(record)}
            className="feather-edit me-2"
          />
          <Trash2
            onClick={() => deleteAd(record.key)}
            className="feather-trash-2"
          />
        </div>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Add Management</h4>
                <h6>Manage Ads</h6>
              </div>
            </div>
            <div className="page-btn">
              <button onClick={handleShow} className="btn btn-added">
                <PlusCircle className="me-2" />
                Add New
              </button>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-responsive">
                <Table
                  className="table table-hover table-center mb-0 datatable"
                  columns={columns}
                  dataSource={addData}
                  rowKey={(record) => record.key}
                />
              </div>
            </div>
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{edit ? "Edit Ad" : "Add New Ad"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="form-group">
                  <label>Upload Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleImageUpload}
                  />
                </div>
                <div className="form-group">
                  <label>Ad URL</label>
                  <input
                    type="text"
                    className="form-control"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={edit ? updateAd : addNewAd}
                  >
                    {edit ? "Update" : "Save"}
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AddManagement;
