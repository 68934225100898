import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";
import jsPDF from "jspdf";
import "jspdf-autotable";

const AdminSkirtSummary = () => {
    const [partyId, setPartyId] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [list, setList] = useState([]);


    useEffect(() => {
        const storedPartyId = localStorage.getItem('partyId');
        if (storedPartyId) {
            setPartyId(storedPartyId);
        }
    }, []);

    const { apiData } = useFetch(`skirtSummaryForAdmin/${partyId}`);

    useEffect(() => {
        if (apiData && apiData.status === "1" && apiData.data) {
            // Extract and transform data for the table
            const formattedData = apiData.data.data.flatMap((region) => 
                region.constituencyResults.map((constituency) => ({
                    regionName: region.regionName || "N/A",
                    totalConstituencies: region.totalConstituencies || 0,
                    constituencyName: constituency.constituencyName || "N/A",
                    presidentialResults: constituency.presidentialResults || "N/A",
                    parliamentaryResults: constituency.parliamentaryResults || "N/A",
                }))
            );
            setTableData(formattedData);
            setList(formattedData);
        }
    }, [apiData]);

    const columns = [
        {
            title: "Region Name",
            dataIndex: "regionName",
            sorter: (a, b) => a.regionName.localeCompare(b.regionName),
        },
        {
            title: "Total Constituencies",
            dataIndex: "totalConstituencies",
            sorter: (a, b) => a.totalConstituencies - b.totalConstituencies,
        },
        {
            title: "Constituency Name",
            dataIndex: "constituencyName",
            sorter: (a, b) => a.constituencyName.localeCompare(b.constituencyName),
        },
        {
            title: "Presidential(%)",
            dataIndex: "presidentialResults",
        },
        {
            title: "Parliamentary(%)",
            dataIndex: "parliamentaryResults",
        },
    ];
    const handleExportPDF = () => {
        const doc = new jsPDF();
        const verticalMargin = 20; // Set the top vertical margin
    
        // Get current date
        const currentDate = new Date().toLocaleDateString("en-US", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    
        // Set PDF title
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Skirt & Blouse Summary", 14, verticalMargin);
    
        // Add current date to the corner
        doc.setFontSize(10);
        doc.setTextColor(100);
        doc.text(
            currentDate,
            doc.internal.pageSize.width - 30,
            verticalMargin,
            null,
            null,
            "right"
        );
    
        // Check if list data is available
        if (list && list.length > 0) {
            // Prepare table data for all constituencies in all regions
            const tableData = list.map((constituency) => [
                constituency.regionName || "No Data",
                constituency.totalConstituencies || "No Data",
                constituency.constituencyName || "No Data",
                constituency.presidentialResults || "No Data",
                constituency.parliamentaryResults || "No Data",
            ]);
    
            // Define table header
            const tableHeaders = [["Region Name", "Total Constituencies", "Constituency Name", "Presidential (%)", "Parliamentary (%)"]];
    
            // Generate a single table with all regions
            doc.autoTable({
                head: tableHeaders,
                body: tableData,
                startY: verticalMargin + 20,
                theme: "striped",
            });
        } else {
            doc.setFont("helvetica", "bold");
            doc.text("Information Not Available", 14, verticalMargin + 20);
        }
    
        // Save the PDF
        doc.save("skirt_summary.pdf");
    };
    
    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Skirt & Blouse Summary</h4>
                            <h6>Summary</h6>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                        <button
                            className="btn btn-success"
                            style={{ marginBottom: "5px" }}
                            onClick={handleExportPDF}
                        >
                            Export to PDF
                        </button>
                    </div>

                {/* Table Section */}
                <div className="card mb-4 shadow my-3">
                    <div className="card-body">
                        <h5 className="card-title">Summary</h5>
                        {/* Render table with data */}
                        <Table
                            columns={columns}
                            dataSource={tableData} // Use the formatted data from state
                            pagination={{ pageSize: 5 }} // Optional pagination
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminSkirtSummary;
