import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../Utils/Loader";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import useFetch from "../../ApiClient/GetApi";

const Settings = () => {
    const [loading, setLoading] = useState(false);
    const [partyId, setPartyId] = useState(null);
    const [nationalId, setNational] = useState(null);
    const [sessionId, setSessionId] = useState("");
    const { apiData } = useFetch(`getAllSessions`);
    const getAllParties = useFetch(`getAllParties`);
    const getSettings = useFetch(`getSettings`);
    
    // Sync local state with fetched settings
    useEffect(() => {
        if (getSettings.apiData?.data?.settings) {
            setPartyId(getSettings.apiData.data.settings.partyId || "");
            setNational(getSettings.apiData.data.settings.nationalPartyId || "");
        }
    }, [getSettings.apiData]);

    // Function to handle emptying the database
    const handleEmptyDatabase = async () => {
        try {
            if (sessionId === "") {
                error_toaster("Please select session first!");
                return false;
            }
            setLoading(true); // Start the loader
            
            let response = await PostApi("emptyDB", { sessionId });
            if (response.data.status === "1") {
                success_toaster(response.data.message);
                setLoading(false);
            } else {
                error_toaster(response.data.message);
                setLoading(false);
            }
        } catch (error) {
            error_toaster(error.message);
            setLoading(false);
        } finally {
            setLoading(false); // Stop the loader
        }
    };

    const submitParty = async () => {
     
        setLoading(true);
        let response = await PostApi("submitParty", { partyId });
        if (response.data.status === "1") {
            success_toaster(response.data.message);
            await getSettings.reFetch(); // Re-fetch settings
            setLoading(false);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    const submitNational = async () => {
        
        setLoading(true);
        let response = await PostApi("submitNational", { nationalId });
        if (response.data.status === "1") {
            success_toaster(response.data.message);
            await getSettings.reFetch(); // Re-fetch settings
            setLoading(false);
        } else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    return (
        <div className="page-wrapper">
            {loading ? (
                <Loader />
            ) : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Settings</h4>
                                <h6>Manage Settings</h6>
                            </div>
                        </div>
                    </div>

                    <div className="card table-list-card p-4">
                        <div className="card-body">
                            <div className="my-2">
                                <select
                                    value={sessionId}
                                    onChange={(e) => setSessionId(e.target.value)}
                                    className="form-control"
                                >
                                    <option value="">Select Session</option>
                                    {apiData?.data?.sessions?.map((dat) => (
                                        <option key={dat.id} value={dat.id}>
                                            {dat.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button
                                style={{ width: "150px" }}
                                className="btn btn-danger"
                                onClick={handleEmptyDatabase}
                            >
                                Empty Database
                            </button>
                        </div>
                    </div>
                    <div className="card table-list-card p-4">
                        <div className="card-body">
                            <h4>Party for Top Candidates</h4>
                            <div className="my-2">
                                <select
                                    value={partyId}
                                    onChange={(e) => setPartyId(e.target.value)}
                                    className="form-control"
                                >
                                    <option value="">No Party</option>
                                    {getAllParties?.apiData?.data?.parties?.map((dat) => (
                                        <option key={dat.id} value={dat.id}>
                                            {dat.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button
                                style={{ width: "150px" }}
                                className="btn btn-danger"
                                onClick={submitParty}
                            >
                                Submit Party
                            </button>
                        </div>
                    </div>
                    <div className="card table-list-card p-4">
                        <div className="card-body">
                            <h4>Party for National Report</h4>
                            <div className="my-2">
                                <select
                                    value={nationalId}
                                    onChange={(e) => setNational(e.target.value)}
                                    className="form-control"
                                >
                                    <option value="">No Party</option>
                                    {getAllParties?.apiData?.data?.parties?.map((dat) => (
                                        <option key={dat.id} value={dat.id}>
                                            {dat.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button
                                style={{ width: "150px" }}
                                className="btn btn-danger"
                                onClick={submitNational}
                            >
                                Submit Party
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;
