import React, { useState, useEffect } from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import Table from "../../core/pagination/datatable";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import { Modal } from "react-bootstrap";
import { Trash2 } from "feather-icons-react/build/IconComponents";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../Utils/urls";

const ConstituencySelection = () => {
    const [selectedConstituency, setSelectedConstituency] = useState(null);
    const [selectedParty, setSelectedParty] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectionData, setSelectionData] = useState([]);
    const [show, setShow] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const handleCaptcha = (value) => {
        setCaptchaToken(value);
    };
    const getAllConsistuencies = useFetch("getAllConsistuencies");
    const getAllParties = useFetch("getAllParties");
    const { apiData,reFetch } = useFetch("getSelectionData"); // API response containing the constituency and party data

    useEffect(() => {
        if (apiData?.data?.data) {
            // Extract and format the data from the API response
            const formattedData = apiData.data.data.map((item) => ({
                key: item.id,
                Constituency: item.constituency.name,
                Party: item.party.name,
            }));
            setSelectionData(formattedData);
        }
    }, [apiData]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAddSelection =async () => {
        if (selectedConstituency && selectedParty) {
            if(apiData?.data?.data?.length > 5){
                error_toaster("You cannot add more than 5!");
                return false;
            }
            if (!captchaToken) {
                error_toaster("Please complete the reCAPTCHA.");
                return;
            }
            setLoading(true);
            let response = await PostApi("addSelection",{partyId:selectedParty,consId:selectedConstituency,captcha: captchaToken});
            if(response.data.status == "1"){
                success_toaster(response.data.message);
                setLoading(false);
                reFetch();
                setShow(false);
            }
            else{
                error_toaster(response.data.message);
                setLoading(false);
            }
        }
        else
        {
            error_toaster("All fields are required");
        }
    };

    const columns = [
        {
            title: "Constituency",
            dataIndex: "Constituency",
            sorter: (a, b) => a.Constituency.length - b.Constituency.length,
            render: (status) => <span className="btn btn-warning btn-sm" style={{ width: "140px" }}>{status}</span>,
        },
        {
            title: "Party",
            dataIndex: "Party",
            sorter: (a, b) => a.Party.length - b.Party.length,
            render: (status) => <span className="btn btn-info btn-sm" style={{ width: "140px" }}>{status}</span>,
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => (
                <div className="edit-delete-action">
                    <Link to="#" className="me-2 p-2" onClick={() => handleDelete(record.key)}>
                        <Trash2 className="feather-trash-2" />
                    </Link>
                </div>
            ),
        },
    ];

    const handleDelete = async(key) => {
        let response = await PostApi("deleteSelection",{id:key});
        if(response.data.status == "1"){
            success_toaster(response.data.message);
            setLoading(false);
            reFetch();
        }
        else{
            setLoading(false);
            error_toaster(response.data.message)
        }
    };

    return (
        <div className="page-wrapper">
          {
            loading ? <Loader/>:  <div>
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Constituency and Party Selection</h4>
                            <h6>Select Constituency and Party</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                        <button onClick={handleShow} className="btn btn-added">
                            <PlusCircle className="me-2" />
                            Add Selection
                        </button>
                    </div>
                </div>

                {/* Table of selected Constituency and Party */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control form-control-sm formsearch"
                                    />
                                    <Link to="#" className="btn btn-searchset">
                                        <Search className="feather-search" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Table columns={columns} dataSource={selectionData} pagination={{ pageSize: 5 }} />
                    </div>
                </div>
            </div>

            {/* Add Constituency and Party Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Constituency and Party</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="p-2">
                        <div className="form-group">
                            <label>Select Constituency</label>
                            <select
                                onChange={(e) => setSelectedConstituency(e.target.value)}
                                className="form-control"
                                value={selectedConstituency || ""}
                            >
                                <option value="">Select Constituency</option>
                                {getAllConsistuencies?.apiData?.data?.consistuencies?.map((cons) => (
                                    <option key={cons.id} value={cons.id}>
                                        {cons.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Select Party</label>
                            <select
                                onChange={(e) => setSelectedParty(e.target.value)}
                                className="form-control"
                                value={selectedParty || ""}
                            >
                                <option value="">Select Party</option>
                                {getAllParties?.apiData?.data?.parties?.map((party) => (
                                    <option key={party.id} value={party.id}>
                                        {party.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </form>
                    <div style={{ padding: "10px 0px" }}>
                                    <ReCAPTCHA
                                        sitekey={SITE_KEY} // Replace with your reCAPTCHA site key
                                        onChange={handleCaptcha}
                                    />
                                </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center w-100 gap-3">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleAddSelection}>
                            Add
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            </div>
          }
        </div>
    );
};

export default ConstituencySelection;
