import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";

const Profile = () => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const { apiData, reFetch } = useFetch(`getProfile/${localStorage.getItem("userId")}`);

  useEffect(() => {
    if (apiData?.data?.profile) {
      setName(apiData.data.profile.name || "");
      setEmail(apiData.data.profile.email || "");
     
      setPhone(apiData.data.profile.phone || "");
    }
  }, [apiData]);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    // Handle form submission
    const profileData = {
      name,
      email,
      phone,
      password,
    };
    setLoading(true);
    let response = await PostApi("updateProfile", {
      id: localStorage.getItem("userId"),
      name: profileData.name,
      email: profileData.email,
   
      phone: profileData.phone,
      password: profileData.password,
    });
    if (response.data.status == "1") {
      success_toaster(response.data.message);
      localStorage.setItem("name",response?.data?.data?.name);
      reFetch();
      setLoading(false);
    }
    else {
      error_toaster(response.data.message);
      setLoading(false);
    }
  };

  return (
    <div className="page-wrapper">
      {
        loading ? <Loader /> : <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Profile</h4>
              <h6>User Profile</h6>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="profile-set">
                <div className="profile-head"></div>
                <div className="profile-top">
                  <div className="profile-content"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
               
                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-sm-12">
                  <div className="input-blocks">
                    <label className="form-label">Password</label>
                    <div className="pass-group">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        className="pass-input form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className={`fas toggle-password ${isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                          }`}
                        onClick={togglePasswordVisibility}
                      ></span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <button onClick={handleSubmit} className="btn btn-submit me-2">
                    Submit
                  </button>
                  <Link to="#" className="btn btn-cancel">
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Profile;
