import React, { useEffect, useState } from "react";
import {  Search, Edit, Trash2} from "react-feather";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import { PlusCircle } from "feather-icons-react/build/IconComponents";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../Utils/urls";
const TeamManagment = () => {

    const [userData, setUserData] = useState([]);
    const [userId , setUserId] = useState("");
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [id, setId] = useState("");
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setName("")
        setEmail("")
        setPassword("")
        setCountryCode("")
        setPhone("")
        setId("")
        setEdit(false)
    };
    const [captchaToken, setCaptchaToken] = useState(null);
    const handleCaptcha = (value) => {
        setCaptchaToken(value);
    };

    const { apiData, reFetch } = useFetch(`getTeamMembers/${userId}`);
    console.log(JSON.stringify(apiData));

    useEffect(() => {
        setUserId(localStorage.getItem('userId'));
        if (apiData?.data?.controllers) {
            const transformedData = apiData?.data?.controllers.map((region) => ({
                id: region.id,
                name: region.name,
                email: region.email,
               
                phoneNum: region.phone,
                phone:  region.phone, // Assuming static values, replace with actual data if available
                role: "Team Member"
            }));
            setUserData(transformedData);
        }
    }, [apiData,userId]);
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.Name.length - b.Name.length,
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.Email.length - b.Email.length,
        },
        {
            title: "Phone No.",
            dataIndex: "phone",

        },
        {
            title: "Role",
            dataIndex: "role",
            sorter: (a, b) => a.Permissions.length - b.Permissions.length,
            render: (status) => <span className="btn btn-success btn-sm" style={{ width: "120px" }}>{status}</span>,
        },

        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <div className="input-block add-lists"></div>

                        <Link className="me-2 p-2">
                            <Edit onClick={() => editUser(record)} className="feather-edit" />
                        </Link>
                        <Link className="confirm-text p-2" to="#" >
                            <Trash2 onClick={() => deleteUser(record.id)} className="feather-trash-2" />
                        </Link>
                    </div>
                </div>
            ),
        },
    ];

    const addController = async () => {
        if(apiData?.data?.controllers?.length > 5){
            error_toaster("You cannot add more than 5 team members");
            return false;
        }
        if (!captchaToken) {
            error_toaster("Please complete the reCAPTCHA.");
            return;
        }
        setLoading(true);
        let response = await PostApi("addTeamMember", {
            name: name,
            controllerId:userId,
            email: email,
            captcha: captchaToken,
            phone: phone,
            password: password
        });
        console.log(response)
        if (response.data.status == "1") {
            setLoading(false);
            reFetch();
            setShow(false);
            success_toaster(response.data.message);
        }
        else {
            setLoading(false);
            error_toaster(response.data.message)
        }
    }

    const deleteUser = async (id) => {
        let response = await PostApi("deleteUser", {
            id: id
        });
        if (response.data.status == "1") {
            success_toaster(response.data.message);
            reFetch();
        }
        else {
            error_toaster(response.data.message);
        }
    }

    const editUser = (obj) => {
        setEdit(true);
        setName(obj.name)
        setEmail(obj.email)
        setPhone(obj.phoneNum)
        setCountryCode(obj.countryCode)
        setPassword(obj.password)
        setId(obj.id)
        setShow(true)
    }

    const updateUser = async () => {
        setLoading(true)
        let response = await PostApi("udpateUser", {
            id: id,
            name: name,
            email: email,
            countryCode: countryCode,
            phone: phone,
            password: password
        });
        if (response.data.status == "1") {
            reFetch();
            setLoading(false);
            setShow(false);
            success_toaster(response.data.message);
        }
        else {
            setLoading(false);
            error_toaster(response.data.message)
        }
    }

    return (
        <div className="page-wrapper">
            {
                loading ? <Loader /> : <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Team Managment</h4>
                                <h6>Manage Team</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">

                        </ul>
                        <div className="page-btn">
                            <button onClick={handleShow} className="btn btn-added">
                                <PlusCircle className="me-2" />
                                Add New
                            </button>
                        </div>
                    </div>

                    {/* Product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input type="text" placeholder="Search" className="form-control form-control-sm formsearch" />
                                        <Link to="#" className="btn btn-searchset">
                                            <Search className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                              
                            </div>
                            
                            <Table columns={columns} dataSource={userData} pagination={{ pageSize: 5 }} />
                        </div>
                    </div>
                    {/* Add Admin Modal */}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{edit ? "Update Team Member" : "Add Team Member"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="p-2">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" placeholder="Enter Name" />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" placeholder="Enter Email" />
                                </div>
                              
                                <div className="form-group">
                                    <label>Phone No.</label>
                                    <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" className="form-control" placeholder="Enter Phone Number" />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input value={password} onChange={(e) => setPassword(e.target.value)} type="text" className="form-control" placeholder="*******" />
                                </div>
                                <div style={{ padding: "10px 0px" }}>
                                    <ReCAPTCHA
                                        sitekey={SITE_KEY} // Replace with your reCAPTCHA site key
                                        onChange={handleCaptcha}
                                    />
                                </div>
                                <div className="d-flex justify-content-center gap-3 mt-3">
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                        Close
                                    </button>
                                    {
                                        edit ? <button onClick={updateUser} type="submit" className="btn btn-primary">
                                            Update
                                        </button> : <button onClick={addController} type="submit" className="btn btn-primary">
                                            Save
                                        </button>
                                    }
                                </div>

                            </form>
                        </Modal.Body>

                    </Modal>

                </div>
            }


        </div>
    );
};

export default TeamManagment;
