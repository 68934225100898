import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../core/pagination/datatable";
import useFetch from "../../ApiClient/GetApi";
import jsPDF from "jspdf";
import "jspdf-autotable";

const AdminNeckReport = () => {
    const [partyId, setPartyId] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [list, setList] = useState([]);

    useEffect(() => {
        const storedPartyId = localStorage.getItem('partyId');
        if (storedPartyId) {
            setPartyId(storedPartyId);
        }
    }, []);

    const { apiData } = useFetch(`neckReportForAdmin/${partyId}`);

    useEffect(() => {
        if (apiData && apiData.status === "1" && Array.isArray(apiData.data)) {
            // Transform and format data for the table
            const formattedData = apiData.data.map((item) => ({
                regionName: item.region || "N/A",
                totalConstituencies: item.constituency || "N/A",
                constituencyName: item.constituency || "N/A",
                winner: `${item.winnerCandidate || "N/A"} (${item.winnerParty || "N/A"})`,
                winnerPercentage: item.winnerVotePercentage || "0%",
                runnerUp: `${item.runnerUpCandidate || "N/A"} (${item.runnerUpParty || "N/A"})`,
                runnerUpPercentage: item.runnerUpVotePercentage || "0%",
                voteDifference: item.voteDifference || 0,
            }));
            setTableData(formattedData);
            setList(formattedData);
        }
    }, [apiData]);

    const columns = [
        {
            title: "Region Name",
            dataIndex: "regionName",
            sorter: (a, b) => a.regionName.localeCompare(b.regionName),
        },
        {
            title: "Constituency Name",
            dataIndex: "constituencyName",
            sorter: (a, b) => a.constituencyName.localeCompare(b.constituencyName),
        },
        {
            title: "Winner",
            dataIndex: "winner",
        },
        {
            title: "Winner (%)",
            dataIndex: "winnerPercentage",
        },
        {
            title: "Runner-Up",
            dataIndex: "runnerUp",
        },
        {
            title: "Runner-Up (%)",
            dataIndex: "runnerUpPercentage",
        },
        {
            title: "Vote Difference",
            dataIndex: "voteDifference",
            sorter: (a, b) => a.voteDifference - b.voteDifference,
        },
    ];
    const handleExportPDF = () => {
        const doc = new jsPDF();
        const verticalMargin = 20; // Set the top vertical margin
    
        // Get current date
        const currentDate = new Date().toLocaleDateString("en-US", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    
        // Set PDF title
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Neck to Neck Summary", 14, verticalMargin);
    
        // Add current date to the corner
        doc.setFontSize(10);
        doc.setTextColor(100);
        doc.text(
            currentDate,
            doc.internal.pageSize.width - 30,
            verticalMargin,
            null,
            null,
            "right"
        );
    
        // Region and Constituency Information
        if (list && list.length > 0) {
            // Define the table headers
            const tableHeaders = [
                "Region Name",
                "Total Constituencies",
                "Constituency Name",
                "Winner",
                "Winner (%)",
                "Runner-Up",
                "Runner-Up (%)",
                "Vote Difference",
            ];
    
            // Constituency Table Data
            const tableData = list.map((constituency) => [
                constituency.regionName,
                constituency.totalConstituencies,
                constituency.constituencyName,
                constituency.winner,
                constituency.winnerPercentage,
                constituency.runnerUp,
                constituency.runnerUpPercentage,
                constituency.voteDifference,
            ]);
    
            // Draw the table
            doc.autoTable({
                head: [tableHeaders],
                body: tableData,
                startY: verticalMargin + 20,
                theme: "striped",
            });
        } else {
            doc.setFont("helvetica", "bold");
            doc.text("Information Not Available", 14, verticalMargin + 20);
        }
    
        // Save the PDF
        doc.save("neck_to_neck_report.pdf");
    };
    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Neck to Neck Report</h4>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                        <button
                            className="btn btn-success"
                            style={{ marginBottom: "5px" }}
                            onClick={handleExportPDF}
                        >
                            Export to PDF
                        </button>
                    </div>

                {/* Table Section */}
                <div className="card mb-4 shadow my-3">
                    <div className="card-body">
                        <h5 className="card-title">Summary</h5>
                        {/* Render table with data */}
                        <Table
                            columns={columns}
                            dataSource={tableData} // Use the formatted data from state
                            pagination={{ pageSize: 5 }} // Optional pagination
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminNeckReport;
