import React, { useEffect, useState } from "react";
import Select from "react-select";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import useFetch from "../../ApiClient/GetApi";
import { PostApi } from "../../ApiClient/PostApi";
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import Loader from "../../Utils/Loader";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../Utils/urls";

const AddController = () => {
    const navigate = useNavigate();
    const [role, setRole] = useState("");
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [partyId, setPartyId] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [rows, setRows] = useState([{
        selectedRegion: null,
        selectedDistricts: [],
        selectedConstituencies: [],
        selectedPollingStations: []
    }]);

    const [regions, setRegions] = useState([]);

    const controllerScreenData = useFetch("controllerScreenData");
    const [captchaToken, setCaptchaToken] = useState(null);
    const handleCaptcha = (value) => {
        setCaptchaToken(value);
    };
    useEffect(() => {
        setRole(localStorage.getItem("role"));
        if (controllerScreenData?.apiData?.data?.data) {
            setRegions(controllerScreenData.apiData.data.data);
        }
        if (role == "admin") {
            setPartyId(localStorage.getItem("partyId"));
        }
    }, [controllerScreenData, role]);

    const handleRegionChange = (selectedRegion, rowIndex) => {
        const updatedRows = [...rows];
        updatedRows[rowIndex].selectedRegion = selectedRegion;
        updatedRows[rowIndex].selectedDistricts = [];
        updatedRows[rowIndex].selectedConstituencies = [];
        updatedRows[rowIndex].selectedPollingStations = [];
        setRows(updatedRows);
    };
    console.log(partyId)
    const handleDistrictChange = (selectedDistrict, rowIndex) => {
        const updatedRows = [...rows];

        if (selectedDistrict) {
            if (!updatedRows[rowIndex].selectedDistricts.some(d => d.value === selectedDistrict.value)) {
                // Add the district if not already selected
                updatedRows[rowIndex].selectedDistricts.push(selectedDistrict);
            } else {
                // Remove the district and its constituencies if it's already selected
                updatedRows[rowIndex].selectedDistricts = updatedRows[rowIndex].selectedDistricts.filter(
                    district => district.value !== selectedDistrict.value
                );
                // Also remove constituencies of the unselected district
                const constituenciesToRemove = regions.find(r => r.id === updatedRows[rowIndex].selectedRegion.value)
                    ?.districts.find(d => d.id === selectedDistrict.value)?.constituencies.map(constituency => constituency.id) || [];

                updatedRows[rowIndex].selectedConstituencies = updatedRows[rowIndex].selectedConstituencies.filter(
                    constituency => !constituenciesToRemove.includes(constituency.value)
                );
            }
        }

        updatedRows[rowIndex].selectedPollingStations = [];
        setRows(updatedRows);
    };
    const handleConstituencyChange = (selectedConstituency, rowIndex) => {
        const updatedRows = [...rows];
        if (selectedConstituency) {
            if (!updatedRows[rowIndex].selectedConstituencies.includes(selectedConstituency)) {
                updatedRows[rowIndex].selectedConstituencies.push(selectedConstituency);
            } else {
                updatedRows[rowIndex].selectedConstituencies = updatedRows[rowIndex].selectedConstituencies.filter(
                    constituency => constituency !== selectedConstituency
                );
            }
        }
        updatedRows[rowIndex].selectedPollingStations = [];
        setRows(updatedRows);
    };

    // const handlePollingStationChange = (selectedPollingStation, rowIndex) => {
    //     const updatedRows = [...rows];
    //     if (selectedPollingStation) {
    //         if (!updatedRows[rowIndex].selectedPollingStations.includes(selectedPollingStation)) {
    //             updatedRows[rowIndex].selectedPollingStations.push(selectedPollingStation);
    //         } else {
    //             updatedRows[rowIndex].selectedPollingStations = updatedRows[rowIndex].selectedPollingStations.filter(
    //                 station => station !== selectedPollingStation
    //             );
    //         }
    //     }
    //     setRows(updatedRows);
    // };

    const selectAllDistricts = (rowIndex) => {
        const updatedRows = [...rows];
        const selectedRegion = updatedRows[rowIndex].selectedRegion;
        const allDistricts = regions.find(r => r.id === selectedRegion.value)?.districts.map(district => ({
            value: district.id,
            label: district.name
        })) || [];
        updatedRows[rowIndex].selectedDistricts = allDistricts;
        setRows(updatedRows);
    };

    const selectAllConstituencies = (rowIndex) => {
        const updatedRows = [...rows];
        const allConstituencies = updatedRows[rowIndex].selectedDistricts.flatMap(district =>
            regions.find(r => r.id === updatedRows[rowIndex].selectedRegion.value)
                ?.districts.find(d => d.id === district.value)?.constituencies.map(constituency => ({
                    value: constituency.id,
                    label: constituency.name
                })) || []
        );
        updatedRows[rowIndex].selectedConstituencies = allConstituencies;
        setRows(updatedRows);
    };

    // const selectAllPollingStations = (rowIndex) => {
    //     const updatedRows = [...rows];
    //     const allPollingStations = updatedRows[rowIndex].selectedConstituencies.flatMap(constituency =>
    //         regions.find(r => r.id === updatedRows[rowIndex].selectedRegion.value)
    //             ?.districts.flatMap(d => d.constituencies.find(c => c.id === constituency.value)?.pollingstations.map(station => ({
    //                 value: station.id,
    //                 label: station.name
    //             })) || [])
    //     );
    //     updatedRows[rowIndex].selectedPollingStations = allPollingStations;
    //     setRows(updatedRows);
    // };

    const addNewRow = (e) => {
        e.preventDefault();
        setRows([...rows, {
            selectedRegion: null,
            selectedDistricts: [],
            selectedConstituencies: [],
            selectedPollingStations: []
        }]);
    };

    const addController = async () => {
        if (!captchaToken) {
            error_toaster("Please complete the reCAPTCHA.");
            return;
        }
        setLoading(true);
        let response = await PostApi("addController", {
            name,
            email,
            phone,
            password,
            captcha: captchaToken,
            partyId,
            regions: rows.map(row => row.selectedRegion?.value),
            districts: rows.flatMap(row => row.selectedDistricts.map(district => district.value)),
            constituencies: rows.flatMap(row => row.selectedConstituencies.map(constituency => constituency.value)),
            // pollingStations: rows.flatMap(row => row.selectedPollingStations.map(station => station.value))
        });
        if (response.data.status == "1") {
            setLoading(false);
            success_toaster(response.data.message);
            if (role == "admin") {
                navigate("/admin-controller-managment")
            }
            else {
                navigate("/controller-managment")
            }

        } else {
            setLoading(false);
            error_toaster(response.data.message);
        }
    };

    return (
        <div className="page-wrapper">
            {loading ? <Loader /> : (
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Add Controller</h4>
                                <h6>Manage Controller</h6>
                            </div>
                        </div>

                    </div>

                    <div className="card table-list-card">
                        <div className="card-body">
                            <form className="p-2">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" placeholder="Enter Name" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" placeholder="Enter Email" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label>Phone No.</label>
                                            <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" className="form-control" placeholder="Enter Phone Number" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input value={password} onChange={(e) => setPassword(e.target.value)} type="text" className="form-control" placeholder="*******" />
                                        </div>
                                    </div>
                                </div>
                                {
                                    role == "super admin" ? <div className="row mt-3">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label>Select Party</label>
                                                <select onChange={(e) => setPartyId(e.target.value)} value={partyId} name="" id="" className="form-control">
                                                    <option value="">Select Party</option>
                                                    {
                                                        controllerScreenData?.apiData?.data?.parties?.map((party) => (
                                                            <option value={party.id} key={party.id}>{party.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                    </div> : ""
                                }

                                {rows.map((row, index) => (
                                    <div key={index} className="row mt-3 p-3" style={{ border: "1px solid gainsboro", margin: "0px", borderRadius: "10px" }}>
                                        <div className="col-md-3">
                                            <label>Select Region</label>
                                            <Select
                                                value={row.selectedRegion}
                                                onChange={(selectedOption) => handleRegionChange(selectedOption, index)}
                                                options={regions.map(region => ({ value: region.id, label: region.name }))}
                                                placeholder="Select Region"
                                            />
                                        </div>

                                        <div className="col-md-9">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>Select Districts</label>
                                                    <Button variant="link" size="sm" onClick={() => selectAllDistricts(index)}>
                                                        Select All
                                                    </Button>
                                                    {row.selectedRegion && regions.find(r => r.id === row.selectedRegion.value)?.districts.map(district => (
                                                        <div key={district.id} className="form-check">
                                                            <input
                                                                type="checkbox"
                                                                id={`district-${district.id}-${index}`}
                                                                className="form-check-input"
                                                                checked={row.selectedDistricts.some(d => d.value === district.id)}
                                                                onChange={() => handleDistrictChange({ value: district.id, label: district.name }, index)}
                                                            />
                                                            <label htmlFor={`district-${district.id}-${index}`} className="form-check-label ms-2">
                                                                {district.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="col-md-4">
                                                    <label>Select Constituencies</label>
                                                    <Button variant="link" size="sm" onClick={() => selectAllConstituencies(index)}>
                                                        Select All
                                                    </Button>
                                                    {row.selectedDistricts.length > 0 && row.selectedDistricts.flatMap(district => (
                                                        <div className="my-2 p-1 shadow-sm" style={{ border: "1px solid #e9edf6", borderRadius: "5px" }}>{regions.find(r => r.id === row.selectedRegion.value)
                                                            ?.districts.find(d => d.id === district.value)?.constituencies.map(constituency => (
                                                                <div key={constituency.id} className="form-check" >
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`constituency-${constituency.id}-${index}`}
                                                                        className="form-check-input"
                                                                        checked={row.selectedConstituencies.some(c => c.value === constituency.id)}
                                                                        onChange={() => handleConstituencyChange({ value: constituency.id, label: constituency.name }, index)}
                                                                    />
                                                                    <label htmlFor={`constituency-${constituency.id}-${index}`} className="form-check-label ms-2">
                                                                        {constituency.name}
                                                                    </label>
                                                                </div>
                                                            ))}</div>
                                                    ))}
                                                </div>

                                                {/* <div className="col-md-4">
                                                    <label>Select Polling Stations</label>
                                                    <Button variant="link" size="sm" onClick={() => selectAllPollingStations(index)}>
                                                        Select All
                                                    </Button>
                                                    {row.selectedConstituencies.length > 0 && row.selectedConstituencies.flatMap(constituency => (
                                                        regions.find(r => r.id === row.selectedRegion.value)
                                                            ?.districts.flatMap(d => d.constituencies.find(c => c.id === constituency.value)?.pollingstations || [])
                                                            .map(station => (
                                                                <div key={station.id} className="form-check">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`station-${station.id}-${index}`}
                                                                        className="form-check-input"
                                                                        checked={row.selectedPollingStations.some(ps => ps.value === station.id)}
                                                                        onChange={() => handlePollingStationChange({ value: station.id, label: station.name }, index)}
                                                                    />
                                                                    <label htmlFor={`station-${station.id}-${index}`} className="form-check-label ms-2">
                                                                        {station.name}
                                                                    </label>
                                                                </div>
                                                            ))
                                                    ))}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="page-btn d-flex justify-content-end p-2">
                                    <button onClick={addNewRow} className="btn btn-warning">
                                        <PlusCircle className="me-2" />
                                        Add New Row
                                    </button>
                                </div>
                                <ReCAPTCHA
                                    sitekey={SITE_KEY} // Replace with your reCAPTCHA site key
                                    onChange={handleCaptcha}
                                />
                                <div className="d-flex justify-content-center gap-3 mt-3">
                                    <button type="button" className="btn btn-secondary" onClick={() => window.history.back()}>
                                        Close
                                    </button>
                                    <button onClick={addController} type="button" className="btn btn-primary">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddController;
